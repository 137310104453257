import { Routes } from "@angular/router";
import { AuthComponent } from "../auth.component";
import { LoginComponent } from "../login/login.component";
import { SignUpComponent } from "../sign-up/sign-up.component";
import { RecoverComponent } from "../recover/recover.component";
import { LoginTermsComponent } from "../login-terms/login-terms.component";
import { LoginPrivacyComponent } from "../login-privacy/login-privacy.component";
import {MobileAlertComponent} from "../mobile/mobile-alert.component";

export const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        data: {
          headerTitle: "Don't have an account?",
          headerCta: "Create account",
        },
      },
      {
        path: "sign-up",
        component: SignUpComponent,
        data: {
          headerTitle: "Do you have an account?",
          headerCta: "Login",
        },
      },
      {
        path: "sign-up/:email",
        component: SignUpComponent,
        data: {
          headerTitle: "Do you have an account?",
          headerCta: "Login",
        },
      },
      {
        path: "terms",
        component: LoginTermsComponent,
        data: {
          headerTitle: "Do you have an account?",
          headerCta: "Login",
        },
      },
      {
        path: "privacy",
        component: LoginPrivacyComponent,
        data: {
          headerTitle: "Do you have an account?",
          headerCta: "Login",
        },
      },
      {
        path: "recover",
        component: RecoverComponent,
      }
    ],
  },
];
