import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GET_STRIPE_CUSTOMER_INFO_ON_APP_LOAD, GET_STRIPE_PRICESLIST_ON_APP_LOAD, UpdateStripeCustomerInfoSubscriptionError, UpdateStripeCustomerInfoSubscriptionSuccess, 
  UpdateStripePricesListError, UpdateStripePricesListSuccess, CREATE_FREE_PLAN_SUBSCRIPTION_ON_APP_LOAD, CreateSubscriptionFromEffect, 
  CREATE_SUBSCRIPTION_FROM_EFFECT, CreateSubscriptionSuccess, CreateSubscriptionError, GET_STRIPE_TAXID_TYPES_ON_APP_LOAD, 
  UpdateStripeTaxidTypesError, UpdateStripeTaxidTypesSuccess, GET_STRIPE_CUSTOMER_TAXID_ON_APP_LOAD, UpdateStripeCustomerTaxidError, 
  UpdateStripeCustomerTaxidSuccess, CREATE_FREE_PLAN_SUBSCRIPTION_PLANS_PAGE, CREATE_SUBSCRIPTION_FROM_PLANS_PAGE, CREATE_SUBSCRIPTION_SUCCESS, 
  UPDATE_SUBSCRIPTION_SUCCESS_PLAN_PAGE, 
  GET_STRIPE_CUSTOMER_INFO_ON_PLANPAGESUBP,
  DELETE_SUBSCRIPTION_FROM_BILLING_PAGE,
  DeleteSubscriptionFromPlansPageSuccess,
  DeleteSubscriptionFromPlansPageError,
  GET_STRIPE_CUSTOMER_INVOICES_BILLINGPAGE,
  UpdateStripeCustomerInvoicesSuccess,
  UpdateStripeCustomerInvoicesError,
  UpdateStripeCustomerError,
  UPDATE_STRIPE_CUSTOMER_INFO_FROM_BILLINGPAGE,
  UpdateStripeCustomerInfoSuccess,
  UpdateStripeCustomerInfoError,
  DELETE_SUBSCRIPTION_FROM_PLANS_PAGE,
  CREATE_SUBSCRIPTION_ENABLING_TRIAL_FROM_PLANS_PAGE,
  DeleteSubscriptionFromPlansPage,
  DeleteSubscriptionFromEnablingTrialEffect,
  DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL,
  DeleteSubscriptionFromEnablingTrialEffectSuccess,
  DeleteSubscriptionFromEnablingTrialEffectError,
  UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS,
  UpdateCrmBasedCustomerinfoSuccess,
  UpdateCrmBasedCustomerinfoError} from "../_actions/user-plan.action";
import { catchError, filter, switchMap, withLatestFrom } from "rxjs/operators";
import { StripeService } from "../../../shared/_service/stripe.service";
import { EMPTY, of } from "rxjs";
import { Store } from "@ngrx/store";
import { UserPlanState } from "../_reducers/user-plan.reducer";
import { getCustomerTaxId, getPrices, getStripeCustomerInfo, getTaxidTypes } from "../_selectors/user-plan.selector";
import {sharedStore} from "astrakode-bc-library";
import { CrmService } from "../../../shared/_service/crm.service";

@Injectable()
export class UserPlanEffects {
    constructor(
        private actions$: Actions,
        private store: Store<UserPlanState>,
        private crmService: CrmService,
        private stripeService: StripeService
    ) {}

    updateStripeCustomerInfo$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UPDATE_STRIPE_CUSTOMER_INFO_FROM_BILLINGPAGE),
        switchMap((action: any) => {
          return this.stripeService.updateCustomer(action.payload).pipe(
            switchMap((result) => ([new UpdateStripeCustomerInfoSuccess(result)])),
            catchError((e) => of(new UpdateStripeCustomerInfoError()))
          );
        })
      );
    });

    getStripeCustomerInfo$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_STRIPE_CUSTOMER_INFO_ON_APP_LOAD, GET_STRIPE_CUSTOMER_INFO_ON_PLANPAGESUBP),
        withLatestFrom(() => this.store.select(getStripeCustomerInfo)),
        filter((action, customerInfo) => Object.keys(customerInfo).length === 0),
        switchMap((customerInfo: any) => {
          return this.stripeService.getCustomerInfo().pipe(
            switchMap((result) => ([new UpdateStripeCustomerInfoSubscriptionSuccess(result)])),
            catchError((e) => of(new UpdateStripeCustomerInfoSubscriptionError()))
          );
        })
      );
    });

    updateCRMPlan$ = createEffect(() => {
      let customerInfo;
      return this.actions$.pipe(
        ofType(UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS),
        switchMap((action: any) => {
          customerInfo = action.payload;
          if (customerInfo?.subscriptions?.data && customerInfo?.subscriptions?.data.length > 0) {                      
            const currentSubscription = customerInfo?.subscriptions?.data[0];
            const properties = [
              {
                label:"akb_plan_status",
                value: currentSubscription.status
              },
              {
                label:"free_trial_activation",
                value: currentSubscription.status == "trialing" ? "Yes" : "No"
              }
            ];
            const crmUpdateData = {
              caller: "UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS Effect",
              customerEmail: customerInfo.email,
              properties: properties,
              action: "general"
            } 
            return this.crmService.updateCRM(crmUpdateData).pipe(
              switchMap((result) => ([new UpdateCrmBasedCustomerinfoSuccess()])),
              catchError((e) => of(new UpdateCrmBasedCustomerinfoError()))
            );
          } else {
            return EMPTY;
          }
        })
      );
    });

    getStripePricesList$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_STRIPE_PRICESLIST_ON_APP_LOAD),
        withLatestFrom(() => this.store.select(getPrices)),
        filter((action, prices) => Object.keys(prices).length === 0),
        switchMap((action: any) => {
          return this.stripeService.getStripePricesList().pipe(
            switchMap((result) => ([new UpdateStripePricesListSuccess(result)])),
            catchError((e) => of(new UpdateStripePricesListError()))
          );
        })
      );
    });

    deleteSubscriptionAfterEnablingTrial$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL),
        switchMap((action: any) => {
          return this.stripeService.cancelSubscriptionAfterEnablingTrial({...action.payload}).pipe(
            switchMap((subscriptionDeleted) => ([
              new DeleteSubscriptionFromEnablingTrialEffectSuccess(subscriptionDeleted)
            ])),
            catchError((e) => of(new DeleteSubscriptionFromEnablingTrialEffectError()))
          );
        })
      );
    });

    deleteSubscription$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(DELETE_SUBSCRIPTION_FROM_BILLING_PAGE, DELETE_SUBSCRIPTION_FROM_PLANS_PAGE),
        switchMap((action: any) => {
          return this.stripeService.cancelSubscription({...action.payload}).pipe(
            switchMap((subscriptionDeleted) => ([
              new DeleteSubscriptionFromPlansPageSuccess(subscriptionDeleted)
            ])),
            catchError((e) => of(new DeleteSubscriptionFromPlansPageError()))
          );
        })
      );
    });

    createSubscriptionSuccess$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CREATE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION_SUCCESS_PLAN_PAGE),
        switchMap((action: any) => {
          return of(new sharedStore.GetPairsCallFromEffect(action.payload.customer));})
      );
    });

    createSubscriptionEnablingTrial$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CREATE_SUBSCRIPTION_ENABLING_TRIAL_FROM_PLANS_PAGE),
        switchMap((action: any) => {
          return this.stripeService.createSubscription({...action.payload}).pipe(
            switchMap((subscriptionCreated) => ([
              new CreateSubscriptionSuccess(subscriptionCreated),
              new DeleteSubscriptionFromEnablingTrialEffect({subscriptionId: action.payload.replacedSubscriptionId})
            ])),
            catchError((e) => of(new CreateSubscriptionError()))
          );
        })
      );
    });

    createSubscription$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CREATE_SUBSCRIPTION_FROM_EFFECT, CREATE_SUBSCRIPTION_FROM_PLANS_PAGE),
        switchMap((action: any) => {
          return this.stripeService.createSubscription({...action.payload}).pipe(
            switchMap((subscriptionCreated) => ([
              new CreateSubscriptionSuccess(subscriptionCreated),
              // new GetPairsCallFromEffect(subscriptionCreated.customer)
            ])),
            catchError((e) => of(new CreateSubscriptionError()))
          );
        })
      );
    });

    createFreePlanSubscription$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CREATE_FREE_PLAN_SUBSCRIPTION_ON_APP_LOAD, CREATE_FREE_PLAN_SUBSCRIPTION_PLANS_PAGE),
        switchMap((action: any) => of(new CreateSubscriptionFromEffect({
          enableAutomaticTax: false, 
          customerId: action.payload.customerId, 
          items: action.payload.items, 
          enableTrial: false, 
          discounts: {}
        })))
      );
    });

    getStripeTaxidTypes$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_STRIPE_TAXID_TYPES_ON_APP_LOAD),
        withLatestFrom(() => this.store.select(getTaxidTypes)),
        filter((action, taxidTypes) => Object.keys(taxidTypes).length === 0),
        switchMap((action, taxidTypes) => {
          return this.stripeService.getSupportedTaxIdTypes().pipe(
            switchMap((result) => ([new UpdateStripeTaxidTypesSuccess(result)])),
            catchError((e) => of(new UpdateStripeTaxidTypesError()))
          );
        })
      );
    });

    getStripeCustomerTaxId$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_STRIPE_CUSTOMER_TAXID_ON_APP_LOAD),
        switchMap((action: any) => {
          return this.stripeService.getCustomerTaxId({customerId: action.payload}).pipe(
            switchMap((result) => ([new UpdateStripeCustomerTaxidSuccess(result)])),
            catchError((e) => of(new UpdateStripeCustomerTaxidError()))
          );
        })
      );
    });

    getStripeCustomerInvoices$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_STRIPE_CUSTOMER_INVOICES_BILLINGPAGE),
        switchMap((action: any) => {
          return this.stripeService.getInvoices().pipe(
            switchMap((result) => ([new UpdateStripeCustomerInvoicesSuccess(result)])),
            catchError((e) => of(new UpdateStripeCustomerInvoicesError()))
          );
        })
      );
    });
}

export const effects = [UserPlanEffects];