import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { effects } from "./_store";
import { SharedStoreModule as LibrarySharedStoreModule} from "astrakode-bc-library";
// import { clearState } from "./_store/_reducers/clearState.metareducer";

@NgModule({
  imports: [
    // shared store
    LibrarySharedStoreModule,
    // effects
    EffectsModule.forFeature(effects),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class SharedStoreModule {
  constructor() {}
}
