<div class="user-actions-wrapper">
    <div class="background">
        <figure style="background-image: url('../../../assets/images/login.svg');"></figure>
        <div class="background-wrapper">
            <div class="claim-wrapper">
                <h1>
                    {{ translations.mainTitle | translate }}
                </h1>
                <img src="../../../assets/images/stars.svg" alt="stars">
                <h2>
                    {{ translations.subTitle | translate }}
                </h2>
                <ul>
                    <li>
                        <h3><i class="iconoir-check-circle"></i> {{ translations.sentence1 | translate }}</h3>
                    </li>
                    <li>
                        <h3><i class="iconoir-check-circle"></i> {{ translations.sentence2 | translate }}</h3>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="form">
        <div class="login-wrapper sign-up">
            <div class="ak-logo">
                <a href="/">
                    <div class="logo-container"></div>
                </a>
                <!--            <div class="login-cta">{{ translations.title | translate }}</div>-->
                <ak-sign-up-form
                        (onUserSignUp)="onSignUp($event)"
                        (onGoogleSignUp)="onGoogleSignUp($event)"
                        (onLinkedinSignUp)="onLinkedinSignUp($event)"
                        (onCaptcha)="onCaptchaToken($event)"
                        [onUserSignUpChange]="errorSubject.asObservable()"
                        [captchaSubject]="captchaSubject"
                        [email]="email"
                        [loginErrMsg]="loginErrMsg"
                ></ak-sign-up-form>
            </div>
        </div>
    </div>
</div>




<!--<div class="sign-up-wrapper">-->
<!--  <div class="sign-up-form">-->
<!--    <div class="ak-logo">-->
<!--      &lt;!&ndash; <a href="/">-->
<!--        <div class="logo-container"></div>-->
<!--      </a> &ndash;&gt;-->
<!--      <div class="sign-up-cta">{{ translations.title | translate }}</div>-->
<!--      <div class="sign-up-sub-cta">{{ translations.subtitle | translate }}</div>-->

<!--    </div>-->
<!--  </div>-->
<!--  <div class="sign-up-hero-text">-->
<!--    &lt;!&ndash; <h2 class="sign-up-hero-title">+1000 Users</h2>-->
<!--    <h3 class="sign-up-hero-caption">Have Already Joined Us!</h3> &ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
