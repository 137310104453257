import { DiagramEffects } from "./diagram.effect";
import { ProjectDraftEffects } from "./project-draft.effect";
import { ProjectsEffects } from "./projects.effect";
import { WorkspacesEffects } from "./workspaces.effect";
import { SharedEffects } from "./shared.effect";

export const effects = [DiagramEffects, ProjectDraftEffects, ProjectsEffects, WorkspacesEffects, SharedEffects];

export * from "./diagram.effect";
export * from "./project-draft.effect";
export * from "./projects.effect";
export * from "./workspaces.effect";
