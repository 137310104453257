import { Injectable } from "@angular/core";
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { AUTH, Auth } from "../_model/auth.model";
import { Observable } from "rxjs";
import { map, first } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthEntityService extends EntityCollectionServiceBase<Auth> {
  constructor(serviceElementFactory: EntityCollectionServiceElementsFactory) {
    super(AUTH, serviceElementFactory);
  }

  // selectEntityById(coreId: number): Observable<Auth> {
  //   return this.entityMap$.pipe(
  //     map(entities => entities[coreId]),
  //     first());
}
