import { createFeatureSelector } from "@ngrx/store";
import { createSelector } from "@ngrx/store";
import { UserPlanState } from "../_reducers/user-plan.reducer";

export const getUserPlanState = createFeatureSelector<UserPlanState>("userPlanStore");

export const getProductFeatureIdsList = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state?.productFeatureIdsList
);

export const getProductFeaturesList = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state?.productFeaturesList
);

export const getStripeId = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.stripeId
);

export const getStripeCustomerInfo = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.customerInfo
);

export const getStripeCurrentSubscription = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.currentSubscription
);

export const isCurrentSubscriptionEmpty = createSelector(
    getStripeCurrentSubscription,
    (currentSubscription) =>  Object.keys(currentSubscription).length > 0 ? false : true
);

export const getPrices = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.prices
);

export const getRecurringInterval = createSelector(
    getUserPlanState,
    (state: UserPlanState) =>  state.recurringInterval
);

export const getRecurringIntervalPrices = createSelector(
    getRecurringInterval,
    getPrices,
    getStripeCurrentSubscription,
    (recurringInterval, prices, currentSubscription) =>  {
        return {
            recurringInterval: recurringInterval,
            prices: prices,
            currentSubscription: currentSubscription
        }
    }
);

export const getPlanType = createSelector(
    getUserPlanState,
    (state: UserPlanState) =>  state.planType
);

export const getPlanTypePrices = createSelector(
    getPlanType,
    getPrices,
    getStripeCurrentSubscription,
    (planType, prices, currentSubscription) =>  {
        return {
            planType: planType,
            prices: prices,
            currentSubscription: currentSubscription
        }
    }
);

export const getStripeCustomerId = createSelector(
    getStripeCustomerInfo,
    (customerInfo) => Object.keys(customerInfo).length > 0 ? customerInfo.id : undefined
);

export const getFreemiumPlan = createSelector(
    getUserPlanState,
    (state: UserPlanState) => {
        const recurringInterval = state.recurringInterval;
        let freemiumPlan = {};
        if (Object.keys(state.prices).length > 0) {
            const freemiumPlanList = state.prices.net.data.filter((price: any) => 
                price.recurring.interval == recurringInterval && price.product.metadata.isFreemium);
    
            if (freemiumPlanList.length > 0) {
                freemiumPlan = freemiumPlanList[0].product;
            }
        }
        return freemiumPlan;
    }
);

// export const getHasUserGotPlan = createSelector(
//     getUserPlanState,
//     (state: UserPlanState) => Object.keys(state.customerInfo).length > 0 && state.customerInfo.subscriptions?.data?.length > 0
// );

export const getCreateFreePlanSubscriptionParams = createSelector(
    getStripeCustomerInfo,
    getFreemiumPlan,
    (customerInfo, freemiumPlan) => {
        return {
            customerId: customerInfo.id,
            items: [{price: freemiumPlan['default_price']}],
            hasUserGotPlan: Object.keys(customerInfo).length > 0 && customerInfo.subscriptions?.data?.length > 0
        }
    }
);

export const getTaxidTypes = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.taxidTypes
);

export const getCustomerTaxId = createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.customerTaxId
);


export const getCustomerInfoAndPrices = createSelector(
    getStripeCustomerInfo,
    getPrices,
    (customerInfo, prices) => {
        return {
            customerInfo: customerInfo,
            prices: prices
        }
    }
);

export const getCustomerInvoices= createSelector(
    getUserPlanState,
    (state: UserPlanState) => state.invoices
);