import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, delay, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  constructor(
    private http: HttpClient,
  ) { }

  getServiceDetails(payload: {serviceTypeId: number}): Observable<any> {
    return this.http.post(`${environment.API_URL}getServiceDetails`, payload);
  }
  /**
   * Asks the API for services
   *
   * @return {*}
   * @memberof ServicesService
   */
  getServices(payload: any): Observable<any> {
    return this.http
    .post(`${environment.API_URL}getServicesTypesList`, {})
    .pipe(
      map((response: any) => {
        if (response?.infos?.length > 0) {
          return [];
        } else {
          return response.servicesTypesList;
        }
      })
    );
  }

  getUserServicesList(payload: any): Observable<any> {
    return this.http
    .post(`${environment.API_URL}getUserServicesList`, {})
    .pipe(
      map((response: any) => {
        if (response?.infos?.length > 0) {
          return [];
        } else {
          return response.userServiceList;
        }
      })
    );
  }
}
