import { environment } from "../../environments/environment";

const AWSCognito = {
  aws_project_region: environment.cognito.aws_project_region,
  aws_cognito_region: environment.cognito.aws_cognito_region,
  aws_user_pools_id: environment.cognito.aws_user_pools_id,
  aws_user_pools_web_client_id:
    environment.cognito.aws_user_pools_web_client_id,
  oauth: {
    domain: environment.cognito.oauth.domain,
    scope: environment.cognito.oauth.scope,
    redirectSignIn: environment.cognito.oauth.redirectSignIn,
    redirectSignOut: environment.cognito.oauth.redirectSignOut,
    responseType: environment.cognito.oauth.responseType,
  },
};

export default AWSCognito;
