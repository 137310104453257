<div class="app-wrapper">
    <ak-header
        [showUserProfileDetailsCompleted$]="showUserProfileDetailsCompleted$"
        [isLoading]="headerLoading$ | async"
        [autoSave]="autoSave$ | async"
        [lastSave]="lastSave$ | async"
        [isPrivacy]="isPrivacy$ | async"
        [isTerms]="isTerms$ | async"
        [isEditingProjectPage]="isEditingProjectPage$ | async"
        [isLoginPage]="isLoginPage$ | async"
        [isSignUpPage]="isSignUpPage$ | async"
        [branchId]="branchId$ | async"
        [projectName]="projectName$ | async"
        (onActionFired)="onActionFired($event)"
        (onToggleMenu)="onToggleMenu()"
        (onDoneNetCompOnBoarding)="onDoneNetCompOnBoarding()"
        *ngIf="!(isLoginOrSignUp | async )"
    ></ak-header>

    <ak-nav
        *ngIf="!(isLoginPage$ | async) && (auth$ | async) && !((isPrivacy$ | async) || (isTerms$ | async) ) && !isMobile"        
        [isHomePage]="isHomePage$ | async"
        [isPlansPage]="isPlansPage$ | async"
        [isProfilePage]="isProfilePage$ | async"
        [isAccountPage]="isAccountPage$ | async"
        [isEditingProjectPage]="isEditingProjectPage$ | async"
        [isMenuOpen]="isMenuOpen$ | async"
        [openedProjects]="openedProjects$ | async"
        [openedWorkspace]="openedWorkspace$ | async"
        [url]="url$ | async"
        [isPrivacy]="isPrivacy$ | async"
        [isTerms]="isTerms$ | async"
        (onClick)="openProjectDialog($event)"
        (onUserLogOut)="goToLoginPage($event)"
        (onDoneOnboardingTour)="onDone()"
    ></ak-nav>

    <div class="content-main-wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-contextbar
        *ngIf="(isEditingProjectPage$ | async)"
        [token]="token"
        [tokenSubject]="tokenSubject"
        [errors]="errors$ | async"
        [isEditingProjectPage]="isEditingProjectPage$ | async"
        (onDoneNetCompOnBoarding)="onDone()"
    ></app-contextbar>
    <div class="upper-right" *ngIf="!(isLoginPage$ | async) && !(isSignUpPage$ | async) && (auth$ | async)">        
        <ak-user-nav
            (onProfile)="goToProfile()"
            (onSubscription)="goToMySubscriptions()"
            [onUserInfoChange]="userSubject.asObservable()"
            (onUserLogOut)="goToLoginPage($event)"
            (onDoneOnboardingTour)="onDone()"
        ></ak-user-nav>
    </div>
    <ng-template #portalHeader>
        {{ translations.header | translate }}
    </ng-template>

    <ng-template #projectsHomeWelcome>
        {{ translations.hello | translate }} {{ (userSubject | async)?.name }}
        <br />
        {{ translations.welcome | translate }}
        <br />
        {{ translations.intro | translate }}
    </ng-template>
</div>
<div id="googleAnalyticsContainer" hidden>

</div>
<!--<lib-conditions-banner [hasAccept]="haveAcceptTerms" (acceptTerms)="acceptTerms($event)"></lib-conditions-banner>-->
