import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


interface MessageMobileTranslations {
  message: string;
}

@Component({
  selector: "app-mobile-alert",
  templateUrl: "./mobile-alert.component.html",
  styleUrls: ["./mobile-alert.component.scss"],
})
export class MobileAlertComponent {

  translations: MessageMobileTranslations = {
    message: "Mobile.Message"
  };
  constructor(
      public dialogRef: MatDialogRef<MobileAlertComponent>,
  ) {}

  close(): void{
    localStorage.setItem('allowMobile', JSON.stringify(true));
    this.dialogRef.close();
  }
}
