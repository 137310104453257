import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { ToasterService } from "astrakode-bc-library";
import { EMPTY, from, of, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  delay,
  map,
  switchMap,
  take,
  tap,
  timeout,
  timeoutWith,
} from "rxjs/operators";
import { INIT_DIAGRAM_XML } from "../../../projects/_consts";
import { ProjectsService } from "../../../projects/_services/projects.service";
// import * as fromDiagram from "../_actions/diagram.actions";
import {sharedStore} from "astrakode-bc-library";
import { CrmService } from "../../../shared/_service/crm.service";

@Injectable()
export class DiagramEffects {
  constructor(
    private actions$: Actions,
    private toasterService: ToasterService,
    private projectsService: ProjectsService,
    private translateService: TranslateService,
    private crmService: CrmService
  ) {}

  // * load components
  loadComponents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_COMPONENTS),
      switchMap((action: sharedStore.LoadComponents) => {
        const branchId = action.payload;
        return this.projectsService.getComponents({ branchId }).pipe(
          map((e) =>
            e?.infos?.length == 0
              ? new sharedStore.LoadComponentsSuccess(e)
              : new sharedStore.LoadComponentsFail(e)
          ),
          catchError((e) => EMPTY)
        );
      })
    );
  });

  loadComponentsFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_COMPONENTS_FAIL),
      switchMap((action: sharedStore.LoadComponentsFail) => {
        this.toasterService.error(sharedStore.LOAD_COMPONENTS_FAIL);

        return of({ type: "EMPTY" });
      })
    );
  });

  // * save branch
  saveBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.SAVE_BRANCH),
      switchMap((action: sharedStore.SaveBranch) => {
        const { branchUuid, xmlString, screen } = action.payload;

        // console.log(xmlString);

        return this.projectsService.saveXML({
            branchUuid,
            xmlString,
            screen,
          }).pipe(
            map((e) => {
              if (e?.infos?.length == 0) {
                this.translateService.get("SuccessMessages.SaveProject").subscribe((data: string) => {
                    this.toasterService.success(data);
                  });
                return new sharedStore.SaveBranchSuccess();
              } else {
                this.translateService.get("ErrorMessages.SaveProject").subscribe((data: string) => {
                    this.toasterService.error(data);
                  });

                return new sharedStore.SaveBranchFail(e.infos);
              }
            }),
            catchError((e) => {
              console.log(e);
              if (e.name === "TimeoutError") {
                this.translateService.get("ErrorMessages.SaveProject").subscribe((data: string) => {
                    this.toasterService.error(data);
                  });
                return of(new sharedStore.SaveBranchFail(e));
              } else {
                return of(new sharedStore.SaveBranchFail(e));
              }
            })
          );
      })
    );
  });

  saveProjectFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.SAVE_BRANCH_FAIL),
      switchMap((action: sharedStore.SaveBranchFail) => {
        this.toasterService.error(sharedStore.SAVE_BRANCH_FAIL);

        return of({ type: "EMPTY" });
      })
    );
  });

  // generate Branch
  generateBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GENERATE_BRANCH),
      switchMap((action: sharedStore.GenerateBranch) => {
        let infos = [];
        return this.projectsService.generate(action.payload).pipe(
          tap((generateResp: any) => infos = generateResp?.infos),
          switchMap((project: any) => {
            const updateCRM = {
              caller: "GENERATE_BRANCH Effect",
              customerEmail: action.payload.creationUser,
              action: "generateNetwork"
            };
            return from(this.crmService.updateCRM(updateCRM));
          }),  
          map((e) => {
            if (infos?.length == 0) {
              return new sharedStore.GenerateBranchSuccess();
            } else {
              return new sharedStore.GenerateBranchFail(new Error(infos[0].description));
            }
          }),
          catchError((e) => {
            return of(new sharedStore.GenerateBranchFail(e))
          })
        );
      })
    );
  });

  generateBranchFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GENERATE_BRANCH_FAIL),
      switchMap((action: sharedStore.GenerateBranchFail) => {
        console.error(sharedStore.GENERATE_BRANCH_FAIL);
        return of({ type: "EMPTY" });
      })
    );
  });

  // update project
  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.UPDATE_PROJECT),
      switchMap((action: sharedStore.UpdateProject) => {
        return this.projectsService.updateProject(action.payload).pipe(
          map((e) => {
            if (e?.infos?.length == 0) {
              return new sharedStore.UpdateProjectSuccess();
            } else {
              return new sharedStore.UpdateProjectFail(e.infos);
            }
          }),
          catchError((e) => of(new sharedStore.UpdateProjectFail(e)))
        );
      })
    );
  });

  updateProjectFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.UPDATE_PROJECT_FAIL),
      switchMap((action: sharedStore.UpdateProjectFail) => {
        this.toasterService.error(sharedStore.UPDATE_PROJECT_FAIL);

        return of({ type: "EMPTY" });
      })
    );
  });

  // load branch
  loadBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_BRANCH),
      switchMap((action: sharedStore.LoadBranch) => {
        if (typeof action.payload == "string") {
          return this.projectsService.getBranchData(action.payload).pipe(
            map((e) => {
              if (e?.infos?.length == 0) {
                return new sharedStore.LoadBranchSuccess(e);
              } else {
                return new sharedStore.LoadBranchFail(e.infos);
              }
            }),
            catchError((e) => of(new sharedStore.LoadBranchFail(e)))
          );
        } else {
          return of(new sharedStore.LoadBranchSuccess(action.payload)).pipe(
            delay(0)
          );
        }
      })
    );
  });

  loadBranchFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_BRANCH_FAIL),
      switchMap((action: sharedStore.LoadBranchFail) => {
        this.toasterService.error(sharedStore.LOAD_BRANCH_FAIL);

        return of({ type: "EMPTY" });
      })
    );
  });

  loadBranchesListByProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_BRANSCHES_BY_PROJECT),
      switchMap((action: sharedStore.LoadBranchesListByProject) => {
        return this.projectsService.getBranchesListByProjects(action.payload).pipe(
          map((e) => {
            if (e?.infos?.length == 0) {
              return new sharedStore.LoadBranchesListByProjectSuccess(e);
            } else {
              return new sharedStore.LoadBranchesListByProjectFail(e.infos);
            }
          }),
          catchError((e) => of(new sharedStore.LoadBranchesListByProjectFail(e))),
          take(1)
        );
      })
    );
  });

  // load project
  loadProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_PROJECT),
      switchMap((action: sharedStore.LoadProject) => {
        if (typeof action.payload == "string") {
          return this.projectsService.getProjectData(action.payload).pipe(
            map((e) => {
              if (e?.infos?.length == 0) {
                return new sharedStore.LoadProjectSuccess(e);
              } else {
                return new sharedStore.LoadProjectFail(e.infos);
              }
            }),
            catchError((e) => of(new sharedStore.LoadProjectFail(e))),
            take(1)
          );
        } else {
          return of(new sharedStore.LoadProjectSuccess(action.payload)).pipe(
            delay(0)
          );
        }
      })
    );
  });

  loadProjectFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.LOAD_PROJECT_FAIL),
      switchMap((action: sharedStore.LoadProjectFail) => {
        this.toasterService.error(sharedStore.LOAD_PROJECT_FAIL);

        return of({ type: "EMPTY" });
      })
    );
  });
}
