import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

constructor(private _router: Router){}

  handleError(error: any): void {
    if (error && (error?.code == "NotAuthorizedException" || error?.rejection?.statusCode === 400) || error == "The user is not authenticated") {
        console.error('Bad Request ERROR:', error);
        localStorage.clear();
        window.location.href = `${environment.HOME_URL}login`;
        // this._router.navigate(["/login"], { replaceUrl: true });
    } else {
      console.error('Error not catched:', error);
    }
  }
}
