import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(private http: HttpClient) { }

  updateCRM(updateCRM: {caller: string, customerEmail: string, action: string, properties?: {label: string, value: any}[]}) {    
    return this.http.post(`${environment.API_URL}AK_CRMUpdate`, updateCRM);
  }
}
