import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Project, PROJECT } from "../_models";
import { environment } from "../../../environments/environment";
import { Auth } from "aws-amplify";
import { UserEntityService } from "../../user/_services";

@Injectable()
export class ProjectsDataService extends DefaultDataService<Project> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private userService: UserEntityService
  ) {
    super(PROJECT, http, httpUrlGenerator);
  }

  /**
   * Returns all the project from the user and storages them in the data storage
   *
   * @return {*}  {Observable<Project[]>}
   * @memberof ProjectsDataService
   */
  getAll(): Observable<Project[]> {
    return Observable.fromPromise(Auth.currentUserInfo()).switchMap((data) => {
      return this.http
        .post(`${environment.API_URL}getprojectsbycreator`, {
          username: data?.attributes?.email,
        })
        .pipe(
          map((res) => {
            return this.formatProjects(res);
          })
        );
    });
  }

  /**
   * Adds a new project from the user and storages them in the data storage
   *
   * @return {*}  {Observable<Project[]>}
   * @memberof ProjectsDataService
   */
  add(project): Observable<Project> {
    return this.http
      .post(`${environment.API_URL}setupnewproject`, project)
      .pipe(
        map((res) => {
          return this.formatProjectCreated(res);
        })
      );
  }

  /**
   * Returns one project by id of the user
   *
   * @return {*}  {Observable<Project[]>}
   * @memberof ProjectsDataService
   */
  getById(key: number | string): Observable<Project> {
    return this.http.get(`${environment.API_URL}getproject?id=${key}`).pipe(
      map((res) => {
        return this.formatProjectInfo(res);
      })
    );
  }

  /**
   * Formats the incoming project to correspond to the project model
   *
   * @param {*} projects
   * @return {*}
   * @memberof ProjectsDataService
   */
  formatProjects(projects) {
    let newObject = projects.listProject.map((data) => {
      let model: Project = {
        id: data.id,
        name: data.name,
        author: data.creationUser,
        lastSaved: data.timestamp ? data.timestamp : 0,
        configuration: {
          version: data.version,
          type: data.type,
        },
        group: {
          id: 0,
          name: "",
          description: data.description,
        },
      };
      return model;
    });
    return newObject;
  }

  /**
   * Formats the incoming new project to correspond to the project model
   *
   * @param {*} projects
   * @return {*}
   * @memberof ProjectsDataService
   */
  formatProjectCreated(project) {
    let model: Project = {
      id: project.project.id,
      name: project.project.name,
      author: project.name,
      lastSaved: project.context.timestamp
        ? parseInt(project.context.timestamp)
        : 0,
      configuration: {
        version: project.project.version,
        type: project.project.type,
      },
      group: {
        id: parseInt(project.group.id),
        name: project.group.name,
        description: project.group.description,
      },
    };
    return model;
  }

  formatProjectInfo(project) {
    let model: Project = {
      id: project.project.id,
      name: project.project.name,
      author: project.project.creationUser,
      lastSaved: project.project.timestamp ? project.project.timestamp : 0,
      configuration: {
        version: project.project.version,
        type: project.project.type,
      },
      group: {
        id: parseInt(project.group.id),
        name: project.group.name,
        description: project.group.description,
      },
    };
    return model;
  }
}
