import { EntityMetadataMap } from "@ngrx/data";

export const USER = "User";

export class User {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  name?: string;
  picture?: string;
  profile?: string;
  sub?: string;
  website?: string;
  'custom:company': string;
}

export const entityMetadata: EntityMetadataMap = {
  User: {
    // Is not is nessesary? Or better for understanding of the code.
    //entityName: USER,
  },
};
