import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
//import * as AWS from 'aws-sdk/global';
import S3 from "aws-sdk/clients/s3";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor() {}

  /**
   * Return the user info from Cognito
   *
   * @return {*}
   * @memberof ProfileService
   */
  getUserInfo() {
    return Observable.fromPromise(Auth.currentUserInfo());
  }

  /**
   * Update the user info using Cognito
   *
   * @param {*} userAttributes
   * @return {*}
   * @memberof ProfileService
   */
  updateUserInfo(userAttributes) {
    const userObservable = new Observable((observer) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.updateUserAttributes(user, userAttributes).then(
          (updateResult) => {
            user.attributes = {...user.attributes, ...userAttributes};
            let userLocalStorage = {
              userId: user.attributes.sub,
              username: user.attributes.email,
              name: user.attributes.name,
              email: user.attributes.email,
              org: user.attributes["custom:company"],
            };
            localStorage.setItem("Auth", JSON.stringify(userLocalStorage));
            observer.next(user);
          },
          (error) => {         
            observer.error(error);
          }
        );
      });
    });
    return userObservable;
  }

  verifyUserAttribute(userAttributes, code) {
    const userObservable = new Observable((observer) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.verifyUserAttributeSubmit(user, "email", code).then(
          (data) => {
          observer.next(data);
          },
          (error) => {         
            observer.error(error);
          }
      );
      });
    });
    return userObservable;
  }

  /**
   * Uploads a new file to S3
   *
   * @param {*} file
   * @return {*}
   * @memberof ProfileService
   */
  fileUpload(file) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region,
    });
    const params = {
      Bucket: "s3-astrokode-img",
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };
    const fileObservable = new Observable((observer) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          observer.next(err);
        }
        observer.next(data);
      });
    });
    return fileObservable;
  }

  /**
   * Changes the user password using Cognito services
   *
   * @param {*} lastPassword
   * @param {*} newPassword
   * @return {*}
   * @memberof ProfileService
   */
  changePassword(lastPassword, newPassword) {
    const userObservable = new Observable((observer) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.changePassword(user, lastPassword, newPassword)
          .then((data) => {
            observer.next(data);
          })
          .catch((error) => {
            observer.error(error);
          });
      });
    });
    return userObservable;
  }
}
