import { EntityMetadataMap } from "@ngrx/data";

export const AUTH = "Auth";

export class Auth {
  userId: string;
  username: string;
  email: string;
  org: string;
}

export const entityMetadata: EntityMetadataMap = {
  Auth: {},
};
