import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
// import { map } from "lodash";
import { Observable, of, Subject, throwError, from } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap, timeoutWith, catchError, tap, take, map } from "rxjs/operators";
// import {map as rxjsMap} from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
  AuditService,
  AuditStatus,
  AuditType,
} from "../../shared/_service/audit.service";
import {sharedModels as fromModels, sharedStore} from "astrakode-bc-library";
import { API_ENDPOINTS } from "../../shared/constants";
import { DefaultWSResponse } from "../../shared/types";
import { CrmService } from "../../shared/_service/crm.service";
import { Store } from "@ngrx/store";
import { StripeService } from "../../shared/_service/stripe.service";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  projectsDomains: any;
  constructor(
    private http: HttpClient, 
    private auditService: AuditService,
    private crmService: CrmService,
    private store: Store<sharedStore.SharedStoreState>,
    private stripeService: StripeService,
  ) {}

  /**
   * Asks the API for the project of the user
   *
   * @return {*}
   * @memberof ProjectsService
   */

  createFromTemplate(templateDetails) {
    return this.http.post(
      `${environment.API_URL}createfromtemplate`,
      templateDetails
    ).pipe(
      switchMap((response: any) => {
        let user = JSON.parse(window.localStorage.getItem("Auth"));
        if (response.infos?.length) {          
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.UseTemplate,
            JSON.stringify({
              event: "CREATE_PROJECT_FROM_TEMPLATE",
              eventDescription: "There has been a problem during the Create From Template action.",
              userEmail: user.email,
              techSpec: {
                serviceName: "createFromTemplate",
                serviceInput: {
                  ...templateDetails,
                  ...response
                },
              }
            })
          );
          throw new TypeError(response.infos[0].code);
        } else {
          this.auditService.generateAudit(
            AuditStatus.Success,
            AuditType.UseTemplate,
            JSON.stringify({
              event: "CREATE_PROJECT_FROM_TEMPLATE",
              eventDescription: "The Create From Template action completed successfully.",
              userEmail: user.email,
              techSpec: {
                serviceName: "createFromTemplate",
                serviceInput: {
                  ...templateDetails,
                  ...response
                },
              }
            })
          );
        }
        return of(response);
      })
    );
  }

  getTemplates(templateFilters) {
    const userInfo = fromPromise(Auth.currentUserInfo());

    return userInfo.pipe<any>(
      switchMap((data) => {
        return this.http.post<{ listWorkspace: any[] }>(
          `${environment.API_URL}gettemplates`,
          {
            username: data.attributes.email,
            ...templateFilters
          }
        );
      })
    );
  }
  
  getTags() {
    return this.http.post(`${environment.API_URL}getTags`, {});
  }

  getProjects() {
    const userInfo = fromPromise(Auth.currentUserInfo());

    return userInfo.pipe<{ listProject: any[] }>(
      switchMap((data) => {
        return this.http.post<{ listProject: any[] }>(
          `${environment.API_URL}getprojectsbycreator`,
          {
            username: data.attributes.email,
          }
        );
      })
    );
  }

  /**
   * Asks the API to create a new project
   *
   * @param {*} project
   * @return {*}
   * @memberof ProjectsService
   */
  createProject(project: any) {
    const logData = JSON.stringify(project);

    return this.http
      .post(`${environment.API_URL}${API_ENDPOINTS.projects.create}`, project)
      .pipe(map((response: DefaultWSResponse) => {
        if (response?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.ProjectCreation,
            JSON.stringify(logData)
          );
          throw new TypeError(response.infos[0].code);
        }
        return response;
      }));      
  }

  /**
   * Asks the API to share a project
   *
   * @param {*} info
   * @return {*}
   * @memberof ProjectsService
   */
  shareProject(info: any) {
    let shareprojectResp: DefaultWSResponse;
    return from(this.http.post(`${environment.API_URL}shareproject`, info)).pipe(
      tap((resp: DefaultWSResponse) => shareprojectResp = resp),
      switchMap((response: any) => {
        const updateCRM = {
          caller: "shareProject Network",
          customerEmail: response.context.userName,
          action: "shareProject"
        };
        return from(this.crmService.updateCRM(updateCRM));
      }),
      map((response: DefaultWSResponse) => {
        if (shareprojectResp?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.ProjectShare,
            JSON.stringify(info)
          );

        }
        return shareprojectResp;
      })
    );
  }

  duplicateProject(project: any) {
    return from(this.http.post(`${environment.API_URL}duplicateproject`, project)).pipe(
      take(1),
      switchMap((response: any) => {
        if (response.infos?.length) {                    
          throw new TypeError(response.infos[0].code);
        }
        return of(response);
      })
    );
  }

  /**
   * Asks the API to launch onUserShare lambda function
   *
   * @param {*} info
   * @return {*}
   * @memberof ProjectsService
   */
  shareProjectCRM(info: any) {
    return Observable.fromPromise(Auth.currentAuthenticatedUser()).pipe(
      tap(user => {
        info.userCrmId = user.attributes["custom:crm_id"]
      }),
      switchMap(resp => this.http.post(`${environment.API_URL}AK_OnUserShare`, info))
    );
  }

  /**
   * Asks the API for the projects domains
   *
   * @return {*}
   * @memberof ProjectsService
   */
  getProjectDomains() {
    return this.http.post(`${environment.API_URL}getprojectdomains`, {});
  }


  /**
   * Reactivate project
   *
   * @return {*}
   * @memberof ProjectsService
   */
  setProjectAccessible(project: any) {
    return this.http.post(`${environment.API_URL}setprojectaccessible`, project);
  }

  /**
   * Aks the API for the versions of a project
   *
   * @param {*} id
   * @return {*}
   * @memberof ProjectsService
   */
  getProjectVersions(id: any) {
    return this.http.post<any>(`${environment.API_URL}getversions`, { id: id });
  }

  /**
   * Asks the API to save the xml of the project
   *
   * @param {fromModels.SaveXML} data
   * @return {*}  {Observable<any>}
   * @memberof ProjectsService
   */
  saveXML(data: fromModels.SaveXML): Observable<any> {
    return this.http.post(`${environment.API_URL}savexmlresource`, data).pipe(map((response: DefaultWSResponse) => {
        if (response?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.ProjectSave,
            JSON.stringify(data)
          );
        }
        return response;
      }));
  }

  /**
   * Asks the API to get the components of a project
   *
   * @param {*} data
   * @return {*}  {Observable<any>}
   * @memberof ProjectsService
   */
  getComponents(data: any): Observable<any> {
    return this.http.post(`${environment.API_URL}getcomponents`, data);
  }

  /**
   * Aks the API to get the branchs of a project
   *
   * @param {string} uuid
   * @return {*}  {Observable<fromModels.BranchData>}
   * @memberof ProjectsService
   */
  getBranchData(uuid: string): Observable<fromModels.BranchData> {
    return this.http.post<fromModels.BranchData>(
      `${environment.API_URL}getbranchdata`,
      {
        uuid,
      }
    );
  }

  /**
   * Asks the API to get the projects data
   *
   * @param {string} uuid
   * @return {*}  {Observable<fromModels.ProjectData>}
   * @memberof ProjectsService
   */
  getProjectData(uuid: string): Observable<fromModels.ProjectData> {
    return this.http.post<fromModels.ProjectData>(
      `${environment.API_URL}getbranchesbyproject`,
      {
        uuid,
      }
    );
  }

   /**
    * 
    * @param projectIds is a list of project uuid 
    * @returns an array where for each projet there is the associated branches list
    * {
    "infoDto": [],
    "projectsAndBranches": [
        {
            "projectUuid": "976328b728be11ef80510a0027000004",
            "branchUuidList": [
                "978bbf5828be11ef80510a0027000004",
                "97pppf5828be11ef80510a0027000004"
            ]
        },
        {
            "projectUuid": "71ee1ea628bf11ef80510a0027000004",
            "branchUuidList": [
                "721d1de728bf11ef80510a0027000004"
            ]
        }
    ]
}
    */
    getBranchesListByProjects(projectIds: string[]): Observable<fromModels.ProjectData> {
      return this.http.post<fromModels.ProjectData>(
        `${environment.API_URL}getBranchesListByProject`,
        {projectIds}
      );
    }

  /**
   * Asks the API to get the workspaces data
   *
   * @param {string} uuid
   * @return {*}  {Observable<fromModels.WorkspaceData>}
   * @memberof ProjectsService
   */
  getWorkspaceData(uuid: string): Observable<fromModels.WorkspaceData> {
    return this.http.post<fromModels.WorkspaceData>(
      `${environment.API_URL}getWorkspacesbyproject`,
      {
        uuid,
      }
    );
  }

  /**
   * Asks the API to update a project
   *
   * @param {fromModels.UpdateProject} data
   * @return {*}  {Observable<any>}
   * @memberof ProjectsService
   */
  updateProject(data: fromModels.UpdateProject): Observable<any> {
    return this.http.post(`${environment.API_URL}updateproject`, data);
  }

  /**
   * Asks the API to deactivates a project branch
   *
   * @param {string} id
   * @return {*}
   * @memberof ProjectsService
   */
  deactivateBranch(id: string) {
    return this.http.post(`${environment.API_URL}deactivatebranch`, {
      id,
    });
  }

  /**
   * Ask API to deactivates all branches
   *
   * @param {string} id
   * @return {*}
   * @memberof ProjectsService
   */
  deactivateBranches(project: any) {
    return this.http.post(`${environment.API_URL}deactivatebranches`, project)
    .pipe(map((response: DefaultWSResponse) => {
      if (response?.infos?.length) {
        this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.ProjectCancellation,
            project.id
        );
      }
      return response;
    }));
  }

  /**
   * Asks the API to save the xml of the project
   *
   * @param {fromModels.GenerateXML} data
   * @return {*}  {Observable<any>}
   * @memberof ProjectsService
   */
  generate(data: fromModels.GenerateXML): Observable<any> {

    return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap((user) => {
      return this.http.post(`${environment.API_URL}generate`, {...data, stripeCustomerId: user.attributes["custom:stripe_id"]})
      .pipe(map((response: DefaultWSResponse) => {
        if (response?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.ProjectGeneration,
            JSON.stringify(data)
          );
        }
        return response;
      }))
    });
  }

  getGenDataForDownload(target: any, project: any) {
    return this.http
      .post(`${environment.API_URL}getgendatafordownload`, {
        target, 
        project
      }).pipe(
        switchMap((response: any) => {
          if (response.infos?.length) {       
            this.auditService.generateAudit(
              AuditStatus.Error,
              AuditType.DownloadGeneratedProject,
              JSON.stringify({...target, ...project})
            );             
            throw new TypeError(response.infos[0].code);
          }
          return of(response);
        })
      )
      .toPromise();
  }

  async checkDownload(target: any) {
    const response = await this.http
      .post(`${environment.API_URL}checkdownload`, target)
      .toPromise();
    return response;
  }

  download(target: any, project: any) {
    return this.http.post(`${environment.API_URL}download`, {
      target, 
      project
    });
  }

  setProjectDomains(projectsdomains: any) {
    this.projectsDomains = projectsdomains;
  }

  getProjectDomainsVariable() {
    return this.projectsDomains;
  }

  /**
   * Asks the API for the workspaces of the user
   *
   * @return {*}
   * @memberof ProjectsService
   */
  getWorkspaces() {
    const userInfo = fromPromise(Auth.currentUserInfo());

    let userAttributes;
    return userInfo.pipe(
      tap(data => userAttributes = data.attributes),
      switchMap((data) => {
        return this.http.post<{ listWorkspace: any[] }>(
          `${environment.API_URL}getworkspacesbycreator`,
          {
            username: userAttributes.email,
          }
        );
      })
    );
  }

  /**
   * Asks the API to create a new workspace
   *
   * @param {*} workspace
   * @return {*}
   * @memberof ProjectsService
   */
  createWorkspace(workspace: any) {
    return this.http
      .post(`${environment.API_URL}setupnewworkspace`, workspace)
      .pipe(map((response: DefaultWSResponse) => {
        if (response?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.WorkspaceNew,
            JSON.stringify(workspace)
          );
        }
        return response;
      }));
  }

  /**
   * Asks the API to update a new workspace
   *
   * @param {*} workspace
   * @return {*}
   * @memberof ProjectsService
   */
  updateworkspace(workspace: any) {
    return this.http.post(`${environment.API_URL}updateworkspace`, workspace);
  }

  /**
   * Asks the API to deactivates a workspace
   *
   * @param {string} id
   * @return {*}
   * @memberof ProjectsService
   */
  deactivateWorkspace(id: string) {
    return this.http
      .post(`${environment.API_URL}deactivateworkspace`, {
        id,
      })
      .pipe(map((response: DefaultWSResponse) => {
        if (response?.infos?.length) {
          this.auditService.generateAudit(
            AuditStatus.Error,
            AuditType.WorkspaceCancellation,
            JSON.stringify(id)
          );
        }
        return response;
      }));
  }
}
