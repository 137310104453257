import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { Observable, EMPTY, from } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { User, USER } from "../_model/user.model";
import "rxjs/add/observable/fromPromise";
import { Auth } from "aws-amplify";
import { environment } from "projects/AstrakodeBCPortal/src/environments/environment";
import {
  AuditService,
  AuditStatus,
  AuditType,
} from "../../shared/_service/audit.service";
import { switchMap, take, tap } from "rxjs/operators";
import { Update } from "@ngrx/entity";

const diagramXML = `<?xml version="1.0" encoding="UTF-8"?>
<astrakode xmlns:astrakode="http://generator.revo.genesys.it/components/instances" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" instancesVersion="2.1.0" xsi:schemaLocation="http://generator.revo.genesys.it/components/instances http://smartshaped.revo.server:8080/REVO_Generator_Schemas/components-instances.xsd " category="bc-composer">
  <astrakode:instances>
  <instance id="P382M1692540F66DN1B" toGenerate="true">
  <component-id>Peer</component-id>
  <attributes>
  <attribute name="name">
  <value>My peer</value>
  </attribute>
  <attribute name="serviceCall">
  <value>rest</value>
  </attribute>
  </attributes>
  </instance>
  <instance id="O222W1692540F66DN1B" toGenerate="true">
  <component-id>Channel</component-id>
  <attributes>
  <attribute name="name">
  <value>My channel</value>
  </attribute>
  </attributes>
  </instance>
  <instance id="" toGenerate="true">
  <component-id>Organization</component-id>
  <attributes>
  <attribute name="name"><value>My Company X</value></attribute>
  <attribute name="ip"><value>192.168.1.5</value></attribute>
  <attribute name="count"><value>12</value></attribute>
  <attribute name="isYou"><value>false</value></attribute>
  </attributes>
  </instance>
  <instance id="" toGenerate="true">
  <component-id>Organization</component-id>
  <attributes>
  <attribute name="name"><value>def</value></attribute>
  <attribute name="ip"><value></value></attribute>
  <attribute name="count"><value></value></attribute>
  <attribute name="isYou"><value>true</value></attribute>
  </attributes>
  </instance>
  <instance id="" toGenerate="true">
  <component-id>Organization</component-id>
  <attributes>
  <attribute name="name"><value>meeee</value></attribute>
  <attribute name="ip"><value></value></attribute>
  <attribute name="count"><value></value></attribute>
  <attribute name="isYou"><value>true</value></attribute>
  </attributes>
  </instance>
  <instance id="" toGenerate="true">
  <component-id>Consortium</component-id>
  <attributes>
  <attribute name="name"><value>My Company Y</value></attribute>
  <attribute name="members"><value>["0","1","2"]</value></attribute>
  <attribute name="organization"><value>0</value></attribute>
  </attributes>
  </instance>
  </astrakode:instances>
  <astrakode:diagram>
  <positions>
  <position ref="P382M1692540F66DN1B" x="100" y="100" width="100" height="100" />
  <position ref="O222W1692540F66DN1B" x="300" y="200" width="500" height="50" />
  </positions>
  </astrakode:diagram>
</astrakode>`;

@Injectable()
export class UsersDataService extends DefaultDataService<User> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private auditService: AuditService
  ) {
    super(USER, http, httpUrlGenerator);
  }

  /**
   * Adds a new user to the store
   *
   * @param {*} [user]
   * @return {*}  {Observable<User>}
   * @memberof UsersDataService
   */
  add(user?): Observable<User> {
    return Observable.fromPromise(Auth.currentUserInfo()).pipe(
      map((res) => {
        return res.attributes;
      })
    );
  }

  update(user: Update<User>): Observable<User> {
  //   {
  //     id: string;
  //     changes: Partial<T>;
  // }
    return Observable.fromPromise(Auth.currentUserInfo()).pipe(
      map((res) => {
        return res.attributes;
      })
    );
  }

  /**
   * Return the user from the store
   *
   * @param {(number | string)} key
   * @return {*}  {Observable<User>}
   * @memberof UsersDataService
   */
  getById(key: number | string): Observable<User> {
    return Observable.fromPromise(Auth.currentUserInfo()).pipe(
      map((res) => {
        res.attributes["id"] = 1;
        return res.attributes;
      })
    );
  }

  // serviceDelete$ = this.http
  //   .post(`${environment.API_URL}deactivateuserprojects`, {})

  //   .switchMap((deactivateuserprojectsResp: any) => {
  //     return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
  //       (user: any) => {
  //         let params = {
  //           email: user.attributes.email,
  //           crm_id: user.attributes["custom:crm_id"],
  //           token: user.signInUserSession.accessToken.jwtToken,
  //           id: user.attributes.sub,
  //           id_token: user.signInUserSession.idToken.jwtToken,
  //           stripe_id: user.attributes["custom:stripe_id"],
  //           contextName: deactivateuserprojectsResp.context.contextName
  //         };
  //         return this.http.post(`${environment.API_URL}AK_deleteUser`, params);
  //       }
  //     );
  //   })
  //   .pipe(
  //     catchError((err) => {
  //       console.error(err);
  //       this.auditService.generateAudit(
  //         AuditStatus.Error,
  //         AuditType.AccountCancellation,
  //         window.localStorage.getItem("Auth")
  //       );
  //       return EMPTY;
  //     })
  //   );

  /**
   * Deletes the user from the store
   *
   * @param {(number | string)} key
   * @return {*}  {(Observable)}
   * @memberof UsersDataService
   */
  delete(key: number | string): Observable<any> {

    let params;
   return from(Observable.fromPromise(Auth.currentAuthenticatedUser())).pipe(
      take(1),
      tap((user: any) => {
        params = {
          email: user.attributes.email,
          crm_id: user.attributes["custom:crm_id"],
          token: user.signInUserSession.accessToken.jwtToken,
          id: user.attributes.sub,
          id_token: user.signInUserSession.idToken.jwtToken,
          stripe_id: user.attributes["custom:stripe_id"]
        };
      }),
      switchMap(user => this.http.post(`${environment.API_URL}AK_deleteUser`, params)),
      switchMap(user => this.http.post(`${environment.API_URL}deactivateuserprojects`, {}))      
    );
    
    // return this.serviceDelete$.pipe(
    //   map(
    //     (res) => {
    //       return "user delete";
    //     },
    //     (error) => {
    //       return "user delete error";
    //     }
    //   )
    // );
  }
}
