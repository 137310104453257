import { NgModule } from "@angular/core";
import { EntityDataModule } from "@ngrx/data";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../environments/environment";
import { EntityDefinitionService, EntityDataService } from "@ngrx/data";
import { UserEntityService, UsersDataService } from "../user/_services";
import { entityMetadata, USER } from "../user/_model";

// import { entityConfig } from "./entity-metadata";

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    EntityDataModule.forRoot({}),

    environment.production ? [] : StoreDevtoolsModule.instrument(),
  ],
  providers: [UserEntityService, UsersDataService],
})
export class AppStoreModule {
  constructor(
    private entityDefinitionService: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private usersDataService: UsersDataService
  ) {
    entityDefinitionService.registerMetadataMap(entityMetadata);
    entityDataService.registerService(USER, usersDataService);
  }
}
