import {sharedStore} from "astrakode-bc-library";

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === sharedStore.ActionTypes.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
