import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/AstrakodeBCPortal/src/environments/environment";
import { API_ENDPOINTS } from "../constants";

export enum AuditType {
  LoginS = "95",
  ProjectCreation = "7",
  AccountCancellation = "8",
  ProjectGeneration = "9",
  ProjectCancellation = "10",
  DownloadGeneratedProject = "11",
  ProjectShare = "12",
  ProjectSave = "13",
  WorkspaceNew = "14",
  WorkspaceCancellation = "15",
  UserAnonymization = "16",
  ProjectDuplication = "17",
  BuildDownloadPack = "18",
  // Stripe events
  getPairs = "19",
  createCustomer = "20",
  getStripeProducts = "21",
  getStripePrices = "22",
  buyProducts = "23",
  buyProductsTrial = "24",
  getInvoices = "25",
  getCustomerInfo = "26",
  getPreviewInvoice = "27",
  getActualSubscription = "28",
  cancelSubscription = "29",
  updateSubscription = "30",
  getPreviewUpdate = "31",
  updatePayment = "32",
  getPaymentMethods = "33",
  addPaymentMethod = "34",
  updateCustomer = "35",
  changeDefault = "36",
  deletePaymentMethod = "37",
  postSignup = "98",
  UseTemplate = "102",
}

export enum AuditStatus {
  Success = "INFO",
  Error = "ERROR",
  Fatal = "FATAL",
  WARN = "WARN",
}

@Injectable({
  providedIn: "root",
})
export class AuditService {
  constructor(private http: HttpClient) {}

  generateAudit(status: AuditStatus, type: AuditType, log: string) {    
    const user = JSON.parse(window.localStorage.getItem("Auth"));
    return this.http
      .post(`${environment.API_URL}${API_ENDPOINTS.audit}`, {
        domain: {
          id: type,
        },
        status,
        jsonInfo: log,
        creationUser: user?.email
      })
      .toPromise();
  }
}
