import { Injectable } from "@angular/core";
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { Project, PROJECT } from "../_models";

@Injectable()
export class ProjectEntityService extends EntityCollectionServiceBase<Project> {
  constructor(serviceElementFactory: EntityCollectionServiceElementsFactory) {
    super(PROJECT, serviceElementFactory);
  }
}
