import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadScriptService {

  private scripts: any = {};

  constructor() { }

  loadProduktly() {
    const scriptUrl = "https://public.produktly.com/js/main.js"
    return new Promise<void>((resolve, reject) => {
      // Check if script is already loaded
      if (this.scripts[scriptUrl]) {
        resolve(this.scripts[scriptUrl]);
      } else {
        // Load the script
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = scriptUrl;
        scriptElement.async = true;
        scriptElement.id = 'produktlyScript';
        scriptElement.setAttribute('data-client-token', "0a201f5421da64bbef8343e19cb4931db61f208a286555fb20b5f8a2232d9cd3a1d64d34a53b0548c59ea0b89c3f0e4d645137fd60f6ebe7213e8f79ae583dd45c6bfb54f7ee2004a35f15475bd9312032f83d6b23b5ce77e581be44d0a7e2a272378065");
        
        scriptElement.onload = () => {
          resolve();
        };

        scriptElement.onerror = () => {
          reject(new Error('Script load error'));
        };

        document.getElementsByTagName('head')[0].appendChild(scriptElement);
      }
    });
  }

  // loadScript(scriptUrl): Promise<any> {
  //   // const scriptUrl = "https://public.produktly.com/js/main.js";
    
  //   return new Promise((resolve, reject) => {
  //     // Check if script is already loaded
  //     if (this.scripts[scriptUrl]) {
  //       resolve(this.scripts[scriptUrl]);
  //     } else {
  //       // Load the script
  //       const scriptElement = document.createElement('script');
  //       scriptElement.type = 'text/javascript';
  //       scriptElement.src = scriptUrl;
  //       scriptElement.onload = () => {
  //         this.scripts[scriptUrl] = { loaded: true, status: 'Loaded' };
  //         resolve(this.scripts[scriptUrl]);
  //       };

  //       scriptElement.onerror = (error: any) => {
  //         this.scripts[scriptUrl] = { loaded: false, status: 'Loaded' };
  //         reject(error);
  //       };

  //       document.getElementsByTagName('head')[0].appendChild(scriptElement);
  //     }
  //   });
  // }
}
