import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UrlTree, Router } from "@angular/router";
import { Auth as AuthAWS } from "aws-amplify";
import { AuthStateService } from "../../shared/_service/auth-state.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(private router: Router, private authService: AuthStateService) {}

  /**
   * checks if there is a user logged in
   *
   * @return {*}  {(Observable<boolean | UrlTree>
   *     | Promise<boolean | UrlTree>
   *     | boolean
   *     | UrlTree)}
   * @memberof AuthGuard
   */
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("remember") === "false") {
        AuthAWS.configure({ storage: sessionStorage });
      }
      AuthAWS.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            resolve(true);
          }
        })
        .catch((e) => {
          this.router.navigateByUrl("/login");
          // this.authService.logout();
          resolve(false);
        });
    });
  }
}
