import { ChangeDetectionStrategy, Component, NgZone, OnDestroy } from "@angular/core";
import { AuthDataService } from "../_services/auth-data.service";
import { Auth as AuthAWS } from "aws-amplify";
import { Subject, from } from "rxjs";
import { switchMap, tap, take, takeUntil, catchError } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService, ToasterService, AuthModalComponent } from "astrakode-bc-library";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthStateService } from "../../shared/_service/auth-state.service";
import { CrmService } from "../../shared/_service/crm.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

interface DownloadModalTranslations {
  title: string;
  mainTitle: string;
  subTitle: string;
  sentence1: string;
  sentence2: string;
}

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnDestroy {
  public errorSubject: Subject<any> = new Subject<any>();
  public captchaSubject: Subject<any> = new Subject<any>();
  email = "";
  loginErrMsg: string;

  translations: DownloadModalTranslations = {
    title: "Register.Title",
    mainTitle: "Login.MainTitle",
    subTitle: "Login.SubTitle",
    sentence1: "Login.Sentence1",
    sentence2: "Login.Sentence2",
  };

  protected _onDestroy = new Subject<void>();

  constructor(
    private authDataService: AuthDataService,
    private authStateService: AuthStateService,
    private router: Router,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private translateService: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private deviceService: DeviceDetectorService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this._onDestroy)).subscribe((params) => {
      this.email = params["email"];
    });
    AuthAWS.currentAuthenticatedUser()
      .then(() => {
        this.zone.run(() => this.router.navigate(["/home"], {}));
      })
      .catch((err) => {
        //this.tosterService.error(err);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sing in the user, using the authData service
   *
   * @param {*} userDetails
   * @memberof SignUpComponent
   */
  onSignUp(userDetails: any) {
    userDetails.username = userDetails.username.toLowerCase();
    userDetails.attributes.email = userDetails.username;
    const leadUser = {
      email: userDetails.username,
      prospect_id: "unknown",
      company_name: userDetails.attributes["custom:company"],
      first_name: userDetails.attributes.name,
      last_name: userDetails.attributes.family_name,
      referral: userDetails.role,
    };

    userDetails = this.setAttributes(userDetails);

    this.recaptchaV3Service.execute('importantAction').pipe(
      takeUntil(this._onDestroy),
      tap(token => {
        userDetails.attributes["custom:recaptchaToken"] = token.substring(0, 1000);
        userDetails.attributes["custom:recaptchaToken2"] = token.substring(1000);
        userDetails.attributes["custom:environmentType"] = environment.TYPE;
      }),
      switchMap(user => from(this.authDataService.getUserGeolocation("OnStandardSignup"))),
      tap(geolocationResp => {
        userDetails.attributes["custom:regCountryName"] = geolocationResp["country_name"];
        userDetails.attributes["custom:regCountryCode"] = geolocationResp["country_code"];
        userDetails.attributes["custom:regRegion"] = geolocationResp["region"];
        userDetails.attributes["custom:regIP"] = geolocationResp["ip"];
      }),
      switchMap(resp => {
        return this.authDataService.cognitoSignUp(userDetails);
      }),
    ).subscribe(
      (data) => {
        const userData = data;
        this.confirmAccount(userData, leadUser);
      },
      (error) => {
        let showToastMsg: boolean = true;
        if (error.code = "UsernameExistsException") {
          showToastMsg = false;
          this.loginErrMsg = error.message;
        }
        this.errorSubject.next(error);
        if (showToastMsg) {
          this.toasterService.error(error.message);
        }
      }
    );
  }

  onLinkedinSignUp(userDetails) {
    this.idpSignIn(userDetails, "Linkedin");
  }

  onGoogleSignUp(userDetails) {
    this.idpSignIn(userDetails, CognitoHostedUIIdentityProvider.Google);
  }

  idpSignIn(userDetails, provider) {
    from(this.authDataService.getUserGeolocation("OnIDPSignup")).pipe(
      takeUntil(this._onDestroy)
    ).subscribe(geolocationResp => {
      userDetails.attributes["custom:regCountryName"] = geolocationResp["country_name"];
      userDetails.attributes["custom:regCountryCode"] = geolocationResp["country_code"];
      userDetails.attributes["custom:regRegion"] = geolocationResp["region"];
      userDetails.attributes["custom:regIP"] = geolocationResp["ip"];
      userDetails.attributes["custom:environmentType"] = environment.TYPE;

      userDetails = this.setAttributes(userDetails);
      localStorage.setItem("userExtraDetails", JSON.stringify(userDetails));

      if (provider == CognitoHostedUIIdentityProvider.Google) {
        AuthAWS.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        });
      } else if (provider == "Linkedin") {
        AuthAWS.federatedSignIn({
          customProvider: "LinkedIn",
        });
      }
    });
  }

  setAttributes(userDetails) {

    const deviceInfo = this.deviceService.getDeviceInfo();
    const userBrowser = deviceInfo.browser + " " + deviceInfo.browser_version;
    const userOS = deviceInfo.os + " " + deviceInfo.os_version;
    let device = "Desktop";

    if (this.deviceService.isMobile()) {
      device = "Mobile";
    } else if (this.deviceService.isTablet()) {
      device = "Tablet";
    }

    userDetails.attributes["custom:regDevice"] = device;
    userDetails.attributes["custom:regBrowser"] = userBrowser;
    userDetails.attributes["custom:regOS"] = userOS;

    return userDetails;
  }

  /**
   * Validates the captcha suing the authData service
   *
   * @param {string} captchaToken
   * @memberof SignUpComponent
   */
  onCaptchaToken(captchaToken: string) {
    // this.reCaptchaV3Service.execute(
    //   environment.CAPTCHA_TOKEN,
    //   "homepage",
    //   (token) => {
    //     this.authDataService.validateCaptchaToken(token).subscribe(
    //       (data) => {
    //         this.captchaSubject.next(true);
    //       },
    //       (error) => {
    //         this.translateService
    //           .get("ErrorMessages.Captcha")
    //           .subscribe((data: string) => {
    //             this.toasterService.error(data);
    //           });
    //       }
    //     );
    //   },
    //   {
    //     useGlobalDomain: false,
    //   }
    // );
  }

  /**
   * Confirms the user account using the authData service
   *
   * @param {*} userData
   * @param {*} leadUser
   * @memberof SignUpComponent
   */
  confirmAccount(userData, leadUser) {
    this.dialogService.open(AuthModalComponent, { class: 'form-dialog' }).pipe(take(1)).subscribe((data) => {
      if (data) {
        if (data.action == "resend") {
          this.authDataService
            .cognitoResendCode(userData.user.getUsername())
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
              (data) => {
                this.errorSubject.next({ message: "Code sent" });
                this.translateService
                  .get("SuccessMessages.CodeSent")
                  .subscribe((data: string) => {
                    this.toasterService.success(data);
                  });
                this.confirmAccount(userData, leadUser);
              },
              (error) => {
                this.errorSubject.next(error);
                this.toasterService.error(error.message);
              }
            );
        } else if (data.action == "confirm") {
          this.authDataService
            .cognitoConfirmSignup(userData.user.getUsername(), data.code)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
              (data) => {
                this.translateService
                  .get("SuccessMessages.SignUp")
                  .subscribe((data: string) => {
                    this.toasterService.message("Sign up", data, "confirmationSignUpId", 'form-dialog');
                  });
                this.router.navigate(["/login"]);
              },
              (error) => {
                this.errorSubject.next(error);
                //this.toasterService.error(error.message);
                console.error(error.message);
                this.confirmAccount(userData, leadUser);
              }
            );
        }
      } else {
        this.translateService
          .get("ErrorMessages.Code")
          .subscribe((data: string) => {
            this.errorSubject.next(data);
            this.toasterService.error(data);
          });
      }
    });
  }
}
