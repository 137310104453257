import { NgModule } from '@angular/core';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { clearState } from "./_store/_reducers/clearState.metareducer";
import { reducer, effects } from "./_store";

@NgModule({
  declarations: [],
  imports: [
    // shared store
    StoreModule.forFeature("userPlanStore", reducer, {
      metaReducers: [clearState],
    }),
    // effects
    EffectsModule.forFeature(effects),
  ],
})
export class UserPlanStoreModule { 
  constructor() {}
}
