import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-recover-password",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverComponent {
  constructor() {}
}
