import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/AstrakodeBCPortal/src/environments/environment";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
  ) { }

  getUserAssets(chaincodeName: string): Observable<any> {
    const functionData = {
      "functionName": "queryAssetsByOwner", 
      "arguments": {}, 
      "chaincodeName": chaincodeName
    };
    return this.http.post(`${environment.API_URL}runServiceFunction`, functionData);
  }

  uploadDocument(payload: any, chaincodeName: string): Observable<any> {        
    const functionData = {
      "functionName": "notarizeAsset", 
      "arguments": { 
        ...payload
      }, 
      "chaincodeName": chaincodeName
    };
    return this.http.post(`${environment.API_URL}runServiceFunction`, functionData);
  }

  updateDocument(payload: any, chaincodeName: string): Observable<any> {
    const functionData = {
      "functionName": "updateAsset", 
      "arguments": { 
        ...payload
      }, 
      "chaincodeName": chaincodeName
    };
    return this.http.post(`${environment.API_URL}runServiceFunction`, functionData);
  }

  getSelectedAsset(payload: any, chaincodeName: string): Observable<any> {
    const functionData = {
      "functionName": "queryAsset", 
      "arguments": { 
        ...payload
      }, 
      "chaincodeName": chaincodeName
    };
    return this.http.post(`${environment.API_URL}runServiceFunction`, functionData);
  }

  deleteDocument(payload: any, chaincodeName: string): Observable<any> {
    const functionData = {
      "functionName": "deleteAsset", 
      "arguments": { 
        ...payload
      }, 
      "chaincodeName": chaincodeName
    };
    return this.http.post(`${environment.API_URL}runServiceFunction`, functionData);
  }

  signDocument(payload: any): Observable<any> {
    // TODO Mock to remove
    return of(payload).pipe(delay(1000));
  }

  getChaincodeId(): Observable<any> {
    // TODO Mock to remove
    return of('dsncsd8ss9833q3').pipe(delay(300));
  }
}
