import { ActionReducerMap } from "@ngrx/store";
import * as userPlanAction from "../_actions/user-plan.action";
import { cloneDeep } from "lodash";

export interface UserPlanState { 
  productFeatureIdsList: string[];
  productFeaturesList: any[];
  stripeId: string;
  customerInfo: any;
  customerTaxId: any;
  currentSubscription: any;
  prices: any;
  recurringInterval: 'year' | 'month';
  planType: 'net' | 'ide' | 'netide';
  taxidTypes: [];
  invoices: any;
}

export const initialState: UserPlanState = {
  productFeatureIdsList: [],
  productFeaturesList: [],
  stripeId: "",
  customerInfo: {},
  customerTaxId: {},
  currentSubscription: {},
  prices: {},
  recurringInterval: 'year',
  planType: 'netide',
  taxidTypes: [],
  invoices: {}
}

export function reducer(
  state = initialState,
  action: userPlanAction.UserPlanActionsList
): UserPlanState {
  switch (action.type) {
    case userPlanAction.ADD_PRODUCT_FEATURES: {
        return {
          ...state,
          productFeatureIdsList: action.payload.productFeatureIdsList,
          productFeaturesList: action.payload.productFeaturesList,
        };
    }
    case userPlanAction.UPDATE_STRIPE_ID: {
      return {
        ...state,
        stripeId: action.payload,
      };
    }
    case userPlanAction.UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_FROM_CREATE:
    case userPlanAction.UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        customerInfo: action.payload,
        currentSubscription: action.payload.subscriptions?.data.length > 0 ? action.payload.subscriptions.data[0] : state.currentSubscription
      };
    }
    case userPlanAction.UPDATE_STRIPE_PRICESLIST_SUCCESS: {
      return {
        ...state,
        prices: action.payload,
      };
    }
    case userPlanAction.UPDATE_SUBSCRIPTION_SUCCESS_PLAN_PAGE:
    case userPlanAction.CREATE_SUBSCRIPTION_SUCCESS: {
      const subscriptionCreated = action.payload;
      let customerInfo = cloneDeep(state.customerInfo);
      customerInfo['subscriptions']['data'] = [];
      customerInfo.subscriptions.data.push(subscriptionCreated);

      return {
        ...state,
        customerInfo: customerInfo,
        currentSubscription: subscriptionCreated ? subscriptionCreated : state.currentSubscription
      };
    }
    case userPlanAction.DELETE_SUBSCRIPTION_SUCCESS: {
      const subscriptionDeleted = action.payload;
      let customerInfo = cloneDeep(state.customerInfo);
      customerInfo['subscriptions']['data'] = [];

      return {
        ...state,
        customerInfo: customerInfo,
        currentSubscription: []
      };
    }
    case userPlanAction.UPDATE_STRIPE_TAXID_TYPES_SUCCESS: {
      return {
        ...state,
        taxidTypes: action.payload,
      };
    }
    case userPlanAction.UPDATE_STRIPE_CUSTOMER_TAXID_SUCCESS: {
      return {
        ...state,
        customerTaxId: action.payload,
      };
    }
    case userPlanAction.UPDATE_PLAN_TYPE: {
      return {
        ...state,
        planType: action.payload,
      };
    }
    case userPlanAction.UPDATE_RECURRING_INTERVAL: {
      return {
        ...state,
        recurringInterval: action.payload,
      };
    }
    case userPlanAction.UPDATE_STRIPE_CUSTOMER_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: action.payload,
      };
    }
    case userPlanAction.UPDATE_STRIPE_CUSTOMER_INFO_SUCCESS: {

      return {
        ...state,
        customerInfo: action.payload,
      };
    }
  }
  return state;
}

// export const reducers: ActionReducerMap<UserPlanState> = {
//   productSubscription: productSubscriptionReducer,
// };
