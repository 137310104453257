<div class="user-actions-wrapper">
    <div class="background">
        <figure style="background-image: url('../../../assets/images/login.svg');"></figure>
        <div class="background-wrapper">
            <div class="claim-wrapper">
                <h1>
                    {{ translations.mainTitle | translate }}
                </h1>
                <img src="../../../assets/images/stars.svg" alt="stars">
                <h2>
                    {{ translations.subTitle | translate }}
                </h2>
            </div>
        </div>
    </div>
    <div class="form">
        <div class="login-wrapper">
            <div class="ak-logo">
                <a href="/">
                    <div class="logo-container"></div>
                </a>
                <!--            <div class="login-cta">{{ translations.title | translate }}</div>-->

            </div>
            <ak-login-form
                    (onUserLogin)="onLogin($event)"
                    (onRecover)="onRecoverPassword()"
                    (onGoogleLogin)="onGoogleLogin()"
                    (onLinkedinLogin)="onLinkedinLogin()"
                    [onUserSignInChange]="errorSubject.asObservable()"
                    [loginErrMsg]="loginErrMsg"
            ></ak-login-form>
        </div>
    </div>
</div>
