
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Auth } from "./_model/auth.model";
import { Auth as AuthAWS } from "aws-amplify";
import { AuthEntityService } from "./_services";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
// import { getIsPrivacy, getIsTerms } from "../shared-store/_store";
import {sharedStore} from "astrakode-bc-library";
import { SharedStoreModule } from "../shared-store/shared-store.module";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  loading$: Observable<boolean>;
  email: any;
  isPrivacy$: Observable<any>;
  isTerms$: Observable<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthEntityService,
    private activatedRoute: ActivatedRoute,
    private store: Store<SharedStoreModule>
  ) {}

  ngOnInit() {
    this.isPrivacy$ = this.store.select(sharedStore.getIsPrivacy);
    this.isTerms$ = this.store.select(sharedStore.getIsTerms);
  }

  /**
   * !!DEPRECATED Dont use
   *
   * @memberof AuthComponent
   */
  getAuth() {
    this.authService.getAll();
  }

  /**
   * !!DEPRECATED Dont use
   *
   * @memberof AuthComponent
   */
  checkAuthStatus() {
    const isLoggedIn = false;
  }
}