import { Injectable } from "@angular/core";
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { User, USER } from "../_model/user.model";

@Injectable()
export class UserEntityService extends EntityCollectionServiceBase<User> {
  constructor(serviceElementFactory: EntityCollectionServiceElementsFactory) {
    super(USER, serviceElementFactory);
  }
}
