
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import {map, catchError, switchMap, share} from "rxjs/operators";
import {sharedStore} from "astrakode-bc-library";
import { StripeService } from "../../../shared/_service/stripe.service";
import { Injectable } from "@angular/core";

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private stripeService: StripeService
  ) {}

  /**
   * Return all the user projecct and save them in the storage
   *
   * @memberof SharedEffects
   */
  getProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.UPDATE_USER_FEATURES_PLAN_LIST , sharedStore.CANCEL_USER_FEATURES_PLAN),
      switchMap((action: sharedStore.UpdateUserFeaturesPlanList) => {
        localStorage.setItem("isBEUpdatedWithUserPlan", "false");
        return this.stripeService.getPairs().pipe(
            switchMap((result) => (
                [
                    new sharedStore.UpdateUserFeaturesPlanListSucess(true),
                    new sharedStore.UpdateProductList({
                        stringPairsWithSeats: result.stringPairsWithSeats,
                        tieredProduct: result.tieredProduct
                    }),
                    new sharedStore.GetPairsCallSuccessUpdate()
                ])),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });

  getPairsCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GET_PAIRS_CALL_ON_SUBSCRIPTION_PAGE, sharedStore.GET_PAIRS_CALL_FROM_EFFECT),
      switchMap((action: {customerId: string}) => {
        localStorage.setItem("isBEUpdatedWithUserPlan", "false");
        return this.stripeService.getPairs(action.customerId).pipe(
            switchMap((result) => (
                [
                  new sharedStore.UpdateUserFeaturesPlanListSucess(true),
                  new sharedStore.UpdateProductList({
                    stringPairsWithSeats: result.stringPairsWithSeats,
                    tieredProduct: result.tieredProduct
                  }),
                  new sharedStore.GetPairsCallSuccess()                    
                ])),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });
}
