import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { map, catchError, switchMap, tap } from "rxjs/operators";
import { ProjectsService } from "../../../projects/_services/projects.service";
// import * as fromProjects from "../_actions/projects.action";
import { DefaultWSResponse } from "../../../shared/types";
import { AuditService, AuditStatus, AuditType } from "../../../shared/_service/audit.service";
// import { GetPairsCallSuccess } from "../_actions";
import { Router } from "@angular/router";
import { CrmService } from "../../../shared/_service/crm.service";
import { sharedStore } from "astrakode-bc-library";

@Injectable()
export class ProjectsEffects {
  constructor(
    private actions$: Actions,
    private projectsService: ProjectsService,
    private router: Router,
    private auditService: AuditService,
    private crmService: CrmService
  ) { }

  /**
   * Return all the user projecct and save them in the storage
   *
   * @memberof ProjectsEffects
   */
  getProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GET_PROJECTS),
      switchMap((action: sharedStore.GetProjects) => {
        return this.projectsService.getProjects().pipe(
          map((e) => {
            return new sharedStore.GetProjectsSuccess(e.listProject);
          }),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });

  deleteProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.DELETE_PROJECT),
      switchMap((action: sharedStore.DeleteProject) => {
        return this.projectsService.deactivateBranches(action.project).pipe(
          switchMap((result) => ([
            new sharedStore.DeleteProjectSuccess(result),
            new sharedStore.GetPairsCallSuccess(),
            new sharedStore.UpdateProductsCounterAfterDeleteProject(action.project)
          ])),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });

  duplicateProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.DUPLICATE_PROJECT),
      switchMap((action: sharedStore.DuplicateProject) => {
        return this.projectsService.duplicateProject(action.project).pipe(
          switchMap((result) => ([
            new sharedStore.GetPairsCallSuccess(),
            new sharedStore.UpdateProductsCounterAfterAddProject(action.project)
          ])),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });

  createFromTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.CREATE_PROJECT_FROM_TEMPLATE_TMP),
      switchMap((action: any) => {
        const templateList = action.payload.templates;
        const templateIds: number[] = templateList.map(temp => temp.id);
        return this.projectsService.createFromTemplate({ templates: templateIds }).pipe(
          tap((result: any) => {
            const workspaceUrl = "/projects?wsid=" + result.workspaceId;
            this.router.navigateByUrl(workspaceUrl);
          }),
          switchMap((result) => ([
            new sharedStore.GetPairsCallSuccess(),
            new sharedStore.CreateProjectFromTemplateSuccess({ result, templateList })
          ])),
          catchError((e) => {
            return of(new sharedStore.CreateProjectFromTemplateError({ e, templateList }));
          })
        );
      })
    );
  });

  createFromTemplateSuccess$ = createEffect(() => {
    let templateDetails;
    return this.actions$.pipe(
      ofType(sharedStore.CREATE_PROJECT_FROM_TEMPLATE_SUCCESS),
      switchMap((action: any) => {
        let user = JSON.parse(window.localStorage.getItem("Auth"));
        const payload = action.payload;
        let isNetwork = false;
        let isLogic = false;
        const templateIds: number[] = payload.templateList.map(temp => {
          if (!isNetwork) {
            isNetwork = temp.type == 1;
          }
          if (!isLogic) {
            isLogic = temp.type == 2;
          }
          return temp.id;
        });
        const updateCRM = {
          caller: "CREATE_PROJECT_FROM_TEMPLATE Effect",
          customerEmail: user.email,
          action: "createProjectFromTemplate",
          template_open: templateIds.join(','),
          isNetwork: isNetwork,
          isLogic: isLogic
        };
        return this.crmService.updateCRM(updateCRM).pipe(
          map(response => new sharedStore.UpdateCRMSuccess({ response }))
        );
      })
    );
  });

  createFromTemplateError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.CREATE_PROJECT_FROM_TEMPLATE_ERROR),
      switchMap((action: any) => {
        let user = JSON.parse(window.localStorage.getItem("Auth"));
        const payload = action.payload;
        const templateIds: number[] = payload.templateList.map(temp => temp.id);
        const updateCRM = {
          caller: "CREATE_PROJECT_FROM_TEMPLATE Effect",
          customerEmail: user.email,
          action: "createProjectFromTemplate",
          template_open_error: templateIds.join(',')
        };
        return this.crmService.updateCRM(updateCRM).pipe(
          map(response => new sharedStore.UpdateCRMSuccess({ response }))
        );
      })
    );
  });

  // createFromTemplateError$ = createEffect(() => {
  //   let templateDetails;
  //   let user = JSON.parse(window.localStorage.getItem("Auth"));
  //   return this.actions$.pipe(
  //     ofType(sharedStore.CREATE_PROJECT_FROM_TEMPLATE_ERROR),
  //     // switchMap((action: any) => {
  //     //   templateDetails = action.templateDetails;
  //     //   return this.auditService.generateAudit(
  //     //     AuditStatus.Error,
  //     //     AuditType.UseTemplate,
  //     //     JSON.stringify({
  //     //       event: "CREATE_PROJECT_FROM_TEMPLATE",
  //     //       eventDescription: "There has been a problem during the Create From Template action.",
  //     //       userEmail: user.email,
  //     //       techSpec: {
  //     //         serviceName: "createFromTemplate",
  //     //         serviceInput: {
  //     //           ...action.templateDetails
  //     //         },
  //     //       }
  //     //     })
  //     //   );
  //     // }),
  //     switchMap((action: {error: any, templates: []}) => {
  //       const updateCRM = {
  //         caller: "CREATE_PROJECT_FROM_TEMPLATE Effect",
  //         customerEmail: user.email,
  //         action: "createProjectFromTemplate",
  //         template_open_error: action.templates.join(',')
  //       };
  //       return of(this.crmService.updateCRM(updateCRM).subscribe());
  //     })
  //   );
  // });
}
