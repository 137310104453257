import { EntityMetadataMap } from "@ngrx/data";

export const PROJECT = "Project";

export class Project {
  id: string;
  name: string;
  description?: string;
  diagramXML?: string;
  author?: string;
  lastSaved?: number;
  configuration?: {
    org?: string;
    channels?: number;
    ordinator?: string;
    type?: string;
    version?: number;
    collabs?: string[];
  };
  group?: {
    id: number;
    name: string;
    description: string;
  };
}

export const entityMetadata: EntityMetadataMap = {
  Project: {},
};
