import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Auth } from "aws-amplify";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AuditService, AuditType, AuditStatus } from "./audit.service";
import { DefaultWSResponse } from "../../shared/types";
import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/switchMap";
import { SharedStoreModule } from "../../shared-store/shared-store.module";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private auditService: AuditService,
    private store: Store<SharedStoreModule>
  ) { }

  private logError(err: any, auditType: AuditType) {    
    this.auditService.generateAudit(
      AuditStatus.Error,
      auditType,
      JSON.stringify(err)
    );
  }

  private logSuccess(params: any, auditType: AuditType) {
    this.auditService.generateAudit(
      AuditStatus.Success,
      auditType,
      JSON.stringify(params)
    );
  }

  getStripeProducts() {
    return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
      (user) => {
        let params = {
          id: user.attributes["custom:stripe_id"],
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeGetProducts`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.getStripeProducts);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.getStripeProducts);
              throw "getStripeProducts Error";
            })
          );
      }
    );
  }
  // getStripePrices(id) {
  //   return this.http
  //     .post(`${environment.API_URL}AK_StripeGetPrices`, { id })
  //     .pipe(
  //       map((response: DefaultWSResponse) => {
  //         this.logSuccess(response, AuditType.getStripePrices);
  //         return response;
  //       }),
  //       catchError((error) => {
  //         this.logError(error, AuditType.getStripePrices);
  //         throw "getStripePrices Error";
  //       })
  //     );
  // }

  getStripePricesList() {
    return this.http
      .post(`${environment.API_URL}AK_StripeGetPricesList`, {})
      .pipe(
        map((response: DefaultWSResponse) => {
          this.logSuccess(response, AuditType.getStripePrices);
          return response;
        }),
        catchError((error) => {
          this.logError(error, AuditType.getStripePrices);
          throw "getStripePrices Error";
        })
      );
  }
  
  // createPaymentIntent() {
  //   return this.http
  //     .post(`${environment.API_URL}AK_StripeCreatePaymentIntent`, {})
  //     .pipe(
  //       map((response: any) => {
  //         //this.logSuccess(response, AuditType.getStripePrices);
  //         return response;
  //       }),
  //       catchError((error) => {
  //         //this.logError(error, AuditType.getStripePrices);
  //         throw "createPaymentIntent Error";
  //       })
  //     );
  // }

  getUserPlanFeaturesList() {
    return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
      (user) => {
        return this.http
          .post(`${environment.API_URL}AK_StripeGetUserPlanFeaturesList`, {customerId: user.attributes["custom:stripe_id"]})
          .pipe(
            map((response: any) => {
              //this.logSuccess(response, AuditType.getUserPlanFeaturesList);
              return response;
            }),
            catchError((error) => {
              //this.logError(error, AuditType.getUserPlanFeaturesList);
              throw "createSubscription Error";
            })
          );
      }
    );    
  }

  listPaymentMethods(createSetupIntentsIn: {customerId: string}) {
    return this.http
      .post(`${environment.API_URL}AK_StripeListPaymentMethods`, createSetupIntentsIn)
      .pipe(
        map((response: any) => {
          //this.logSuccess(response, AuditType.getStripePrices);
          return response;
        }),
        catchError((error) => {
          //this.logError(error, AuditType.getStripePrices);
          throw "createSubscription Error";
        })
      );
  }
  
  retrievePaymentMethods(createSetupIntentsIn: {paymentMethodId: string}) {
    return this.http
      .post(`${environment.API_URL}AK_StripeRetrievePaymentMethods`, createSetupIntentsIn)
      .pipe(
        map((response: any) => {
          //this.logSuccess(response, AuditType.getStripePrices);
          return response;
        }),
        catchError((error) => {
          //this.logError(error, AuditType.getStripePrices);
          throw "createSubscription Error";
        })
      );
  }

  createSetupIntents(createSetupIntentsIn: {customerId: string}) {
    return this.http
      .post(`${environment.API_URL}AK_StripeCreateSetupIntents`, createSetupIntentsIn)
      .pipe(
        map((response: any) => {
          //this.logSuccess(response, AuditType.getStripePrices);
          return response;
        }),
        catchError((error) => {
          //this.logError(error, AuditType.getStripePrices);
          throw "createSubscription Error";
        })
      );
  }

  // getSetupIntents(createSetupIntentsIn: {customerId: string}) {
  //   return this.http
  //     .post(`${environment.API_URL}AK_StripeGetSetupIntents`, createSetupIntentsIn)
  //     .pipe(
  //       map((response: any) => {
  //         //this.logSuccess(response, AuditType.getStripePrices);
  //         return response;
  //       }),
  //       catchError((error) => {
  //         //this.logError(error, AuditType.getStripePrices);
  //         throw "createSubscription Error";
  //       })
  //     );
  // }

  createSubscription(createSubscriptionIn: {enableAutomaticTax: boolean, customerId: string, items: {price: string}[], enableTrial: boolean, discounts: any}) {
    return this.http
      .post(`${environment.API_URL}AK_StripeCreateSubscription`, createSubscriptionIn)
      .pipe(
        map((response: any) => {
          //this.logSuccess(response, AuditType.getStripePrices);
          return response;
        }),
        catchError((error) => {
          //this.logError(error, AuditType.getStripePrices);
          throw "createSubscription Error";
        })
      );
  }

  updateSubscription(updateSubscription: {recaptchaToken: string, environmentType: string, subscriptionId: string, itemsToAdd: {price: string}[], isChangingPlanSubscription: boolean, discounts?: any, defaultPaymentMethodId: string, enableAutomaticTax: boolean, customerId: string, enableTrial: boolean}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeUpdateSubscription `, updateSubscription)
    .pipe(
      map((response: any) => {
        //commented because the updateSubscription is called only from the payment checkout component and in that case,
        //we don't need to call the getpairs
        // this.store.dispatch(new UpdateSubscriptionSuccessStripeService(response));
        return response;
      }),
      catchError((error) => {
        // this.store.dispatch(new UpdateSubscriptionErrorStripeService());
        throw "updateSubscription Error";
      })
    );
  }

  cancelSubscription(cancelSubscriptionIn: {subscriptionId: string}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeDeleteSubscription `, cancelSubscriptionIn)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }

  cancelSubscriptionAfterEnablingTrial(cancelSubscriptionIn: {subscriptionId: string}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeDeleteSubscriptionAfterEnablingTrial `, cancelSubscriptionIn)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }

  retrieveSubscription(input: {subscriptionId: string}) {
    return this.http
      .post(`${environment.API_URL}AK_StripeRetrieveSubscription`, input)
      .pipe(
        map((response: any) => {
          //this.logSuccess(response, AuditType.getStripePrices);          
          return response;
        }),
        catchError((error) => {
          //this.logError(error, AuditType.getStripePrices);
          throw "createSubscription Error";
        })
      );
  }

  updateCustomer(customer: {customerId: string, detailsToUpdate: any}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeUpdateCustomer `, customer)
    .pipe(
      map((response: any) => {
        this.logSuccess(response, AuditType.updateCustomer);
        return response;
      }),
      catchError((error) => {
        this.logError(error, AuditType.updateCustomer);
        throw "updateCustomer Error";
      })
    );
  }

  getSupportedTaxIdTypes() {
    return this.http
    .post(`${environment.API_URL}AK_StripeGetSupportedTaxIdTypes`, {})
    .pipe(
      map((response: any) => {
        // this.logSuccess(response, AuditType.updateCustomer);
        return response;
      }),
      catchError((error) => {
        // this.logError(error, AuditType.updateCustomer);
        throw "getSupportedTaxIdTypes Error";
      })
    );
  }

  deleteSubscriptionItem(subscriptionItemIdsList: string[]) {
    return this.http
    .post(`${environment.API_URL}AK_StripeDeleteSubscriptionItem `, {subscriptionItemIdsList: subscriptionItemIdsList})
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "updateSubscription Error";
      })
    );
  }

  retrieveUpcomingInvoice(retrieveUpcomingInvoiceIn: {recaptchaToken: string, environmentType: string, customerId: string, subscriptionId: string, retrieveUpcomingItems: {price: string, deleted: boolean}[], isChangingPlanSubscription: boolean, discounts?: {promotion_code: string}, customerDetails?: {customer_details: any}}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeRetrieveUpcomingInvoice `, retrieveUpcomingInvoiceIn)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }

  retrievePromotionCode(retrievePromotionCode: {subscriptionId: string, promotionCode: string}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeRetrievePromotionCode `, retrievePromotionCode)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }

  applyCoupon(applyCoupon: {subscriptionId: string, promotionCode: string}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeApplyCoupon `, applyCoupon)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }

  getCustomerTaxId(customerID: {customerId: string}) {
    return this.http
    .post(`${environment.API_URL}AK_StripeGetCustomerTaxId `, customerID)
    .pipe(
      map((response: any) => {
        //this.logSuccess(response, AuditType.getStripePrices);
        return response;
      }),
      catchError((error) => {
        //this.logError(error, AuditType.getStripePrices);
        throw "RetrieveUpcomingInvoice Error";
      })
    );
  }


  // buyProducts(paymentMethodId, priceObject, metadata) {
  //   return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
  //     (user) => {
  //       let params = {
  //         customerId: user.attributes["custom:stripe_id"],
  //         priceObject,
  //         paymentMethodId: paymentMethodId,
  //         email: user.attributes.email,
  //         metadata,
  //       };

  //       return this.http
  //         .post(`${environment.API_URL}AK_StripeSubscribe`, params)
  //         .pipe(
  //           map((response: DefaultWSResponse) => {
  //             this.logSuccess(response, AuditType.buyProducts);
  //             return response;
  //           }),
  //           catchError((error) => {
  //             this.logError(error, AuditType.buyProducts);
  //             throw "buyProducts Error";
  //           })
  //         );
  //     }
  //   );
  // }
  // buyProductsTrial(priceObject, metadata, trial) {
  //   return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
  //     (user) => {
  //       let params = {
  //         customerId: user.attributes["custom:stripe_id"],
  //         priceObject,
  //         email: user.attributes.email,
  //         metadata,
  //         trial,
  //         lang: this.translateService.getBrowserLang() || 'enUS',
  //       };

  //       return this.http
  //         .post(`${environment.API_URL}AK_StripeSubscribe`, params)
  //         .pipe(
  //           map((response: DefaultWSResponse) => {
  //             this.logSuccess(response, AuditType.buyProductsTrial);
  //             return response;
  //           }),
  //           catchError((error) => {
  //             this.logError(error, AuditType.buyProductsTrial);
  //             throw "buyProductsTrial Error";
  //           })
  //         );
  //     }
  //   );
  // }
  getInvoices() {
    return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
      (user) => {
        let params = {
          id: user.attributes["custom:stripe_id"],
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeCustomerInvoices`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.getInvoices);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.getInvoices);
              throw "getInvoices Error";
            })
          );
      }
    );
  }
  getCustomerInfo() {
    return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
      (user) => {
        let params = {
          id: user.attributes["custom:stripe_id"],
        };
        return this.http
          .post(`${environment.API_URL}AK_StripeCustomerInfo`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.getCustomerInfo);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.getCustomerInfo);
              throw "getCustomerInfo Error";
            })
          );
      }
    );
  }
  createCustomer(user) {
    let params = {
      id: user.attributes["custom:stripe_id"],
      email: user.attributes.email,
      name: user.attributes.name + " " + user.attributes.family_name,
      userLocale: user.attributes["custom:lang"],
      userPoolId: user.pool.userPoolId,
    };

    return this.http
            .post(`${environment.API_URL}AK_StripeCreateCustomer`, params)
            .pipe(
              map((response: DefaultWSResponse) => {
                //this.logSuccess(response, AuditType.getCustomerInfo);
                return response;
              }),
              catchError((error) => {
                //this.logError(error, AuditType.getCustomerInfo);
                throw "createCustomer Stripe Error";
              })
            );
  }
  // getPreviewInvoice(subscriptionItems) {
  //   return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
  //     (user) => {
  //       let params = {
  //         customerId: user.attributes["custom:stripe_id"],
  //         subscriptionItems,
  //       };

  //       return this.http.post(
  //         `${environment.API_URL}AK_StripeGetTotalPrice`,
  //         params
  //       );
  //       // .pipe(
  //       //   map((response: DefaultWSResponse) => {
  //       //     this.logSuccess(response);
  //       //     return response;
  //       //   }),
  //       //   catchError((error) => {
  //       //     this.logError(error);
  //       //     throw "getPreviewInvoice Error";
  //       //   })
  //       // );
  //     }
  //   );
  // }
  getActualSubscription() {
    return Observable.fromPromise(
      Auth.currentAuthenticatedUser({ bypassCache: true })
    ).switchMap((user) => {
      if (user.attributes["custom:stripe_subs_id"]) {
        let params = {
          id: user.attributes["custom:stripe_subs_id"],
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeCustomerSubs`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.getActualSubscription);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.getActualSubscription);
              throw "getActualSubscription Error";
            })
          );
      } else {
        return of({ messages: "NoSubscription" });
      }
    });
  }
  // updatePayment(paymentMethodId, data) {
  //   return Observable.fromPromise(Auth.currentAuthenticatedUser()).switchMap(
  //     (user) => {
  //       let params = {
  //         paymentMethodId: paymentMethodId,
  //         billingDetails: data.billingDetails,
  //         cardInfo: data.card,
  //       };

  //       return this.http
  //         .post(`${environment.API_URL}AK_StripeUpdatePayment`, params)
  //         .pipe(
  //           map((response: DefaultWSResponse) => {
  //             this.logSuccess(response, AuditType.updatePayment);
  //             return response;
  //           }),
  //           catchError((error) => {
  //             this.logError(error, AuditType.updatePayment);
  //             throw "updatePayment Error";
  //           })
  //         );
  //     }
  //   );
  // }
  // getPaymentMethods() {
  //   return Observable.fromPromise(
  //     Auth.currentAuthenticatedUser({ bypassCache: true })
  //   ).switchMap((user) => {
  //     if (user.attributes["custom:stripe_id"]) {
  //       let params = {
  //         customerId: user.attributes["custom:stripe_id"],
  //       };

  //       return this.http
  //         .post(`${environment.API_URL}AK_StripePaymentMethods`, params)
  //         .pipe(
  //           map((response: DefaultWSResponse) => {
  //             this.logSuccess(response, AuditType.getPaymentMethods);
  //             return response;
  //           }),
  //           catchError((error) => {
  //             this.logError(error, AuditType.getPaymentMethods);
  //             throw "getPaymentMethods Error";
  //           })
  //         );
  //     } else {
  //       return of({ messages: "NoSubscription" });
  //     }
  //   });
  // }
  addPaymentMethod(paymentMethodId, paymentMethodDefault?) {
    return Observable.fromPromise(
      Auth.currentAuthenticatedUser({ bypassCache: true })
    ).switchMap((user) => {
      if (user.attributes["custom:stripe_id"]) {
        let params = {
          customerId: user.attributes["custom:stripe_id"],
          paymentMethodId: paymentMethodId,
          paymentMethodDefault: paymentMethodDefault,
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeAddPayment`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.addPaymentMethod);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.addPaymentMethod);
              throw "addPaymentMethod Error";
            })
          );
      } else {
        return of({ messages: "NoSubscription" });
      }
    });
  }
  updateUser(customerInfo) {
    return Observable.fromPromise(
      Auth.currentAuthenticatedUser({ bypassCache: true })
    ).switchMap((user) => {
      if (user.attributes["custom:stripe_id"]) {
        let params = {
          customerId: user.attributes["custom:stripe_id"],
          customerInfo,
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeUpdateUser`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.updateCustomer);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.updateCustomer);
              throw "updateUser Error";
            })
          );
      } else {
        return of({ messages: "NoSubscription" });
      }
    });
  }
  changeDefault(paymentMethodId) {
    return Observable.fromPromise(
      Auth.currentAuthenticatedUser({ bypassCache: true })
    ).switchMap((user) => {
      if (user.attributes["custom:stripe_id"]) {
        let params = {
          customerId: user.attributes["custom:stripe_id"],
          paymentMethodDefault: paymentMethodId,
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeAddPayment`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.changeDefault);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.changeDefault);
              throw "changeDefault Error";
            })
          );
      } else {
        return of({ messages: "NoSubscription" });
      }
    });
  }
  deletePaymentMethod(paymentMethodId) {
    return Observable.fromPromise(
      Auth.currentAuthenticatedUser({ bypassCache: true })
    ).switchMap((user) => {
      if (user.attributes["custom:stripe_id"]) {
        let params = {
          paymentMethodId,
        };

        return this.http
          .post(`${environment.API_URL}AK_StripeDeletePaymentMethod`, params)
          .pipe(
            map((response: DefaultWSResponse) => {
              this.logSuccess(response, AuditType.deletePaymentMethod);
              return response;
            }),
            catchError((error) => {
              this.logError(error, AuditType.deletePaymentMethod);
              throw "deletePaymentMethod Error";
            })
          );
      } else {
        return of({ messages: "NoSubscription" });
      }
    });
  }
  getPairs(customerId?) {
    return Observable.fromPromise(Auth.currentSession()).switchMap(
      (userSession: any) => {
        return Observable.fromPromise(
          Auth.currentAuthenticatedUser({ bypassCache: true })
        ).switchMap((user: any) => {
          let params = {
            id_token: userSession.idToken.jwtToken,
            customerId: user.attributes["custom:stripe_id"] ? user.attributes["custom:stripe_id"] : customerId,
          };

          return this.http
            .post(`${environment.API_URL}AK_StripeFunctionsFomatch`, params)
            .pipe(
              map((response: DefaultWSResponse) => {
                this.logSuccess(response, AuditType.getPairs);
                // this.store.dispatch(new UpdateBEWithUserPlan(true));
                localStorage.setItem("isBEUpdatedWithUserPlan", "true");
                return response;
              }),
              catchError((error) => {
                this.logError(error, AuditType.getPairs);
                throw "getPairs Error";
              })
            );
        });
      }
    );
  }
}
