import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import Amplify from "aws-amplify";
import awsconfig from "./app/auth/aws-exports";

// if (
//   environment.SENTRY_DSN &&
//   environment.TRACING_ORIGIN &&
//   environment.TRACES_SAMPLE_RATE
// ) {
//   Sentry.init({
//     dsn: environment.SENTRY_DSN,
//     environment: environment.production ? "prod" : "dev",
//     autoSessionTracking: true,
//     integrations: [
//       new Integrations.BrowserTracing({
//         tracingOrigins: environment.TRACING_ORIGIN,
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//     ],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: environment.TRACES_SAMPLE_RATE,
//   });
// }

Amplify.configure(awsconfig);

function appendHotjarScript() {
  /* Hotjar Tracking  Code for platform.astrakode.tech */
  const script = document.createElement("script");

  script.innerText = `
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 2116010, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  `;

  document.head.append(script);
}

function appendGoogleTagManager() {
  /* Google Tag Manager */
  const script = document.createElement("script");

  // script.innerText = `
  //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-W8C43LN');
  // `;

  document.head.append(script);
}

function appendGoogleAnalytics() {
  /* Google Analytics */
  const asyncScript = document.createElement("script");

  asyncScript.src =
    "https://www.googletagmanager.com/gtag/js?id=UA-106528401-5";
  asyncScript.async = true;

  const script = document.createElement("script");

  script.innerText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-106528401-5');
  `;

  document.head.append(asyncScript);
  document.head.append(script);
}

if (environment.production) {
  enableProdMode();

  appendHotjarScript();
  appendGoogleTagManager();
  appendGoogleAnalytics();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
