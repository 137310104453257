import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';

import {
  DialogService,
} from 'astrakode-bc-library';
import {MobileAlertComponent} from "../mobile/mobile-alert.component";
import {take} from "rxjs/operators";

@Injectable()
export class MobileGuard implements CanActivate {
  constructor(private deviceService: DeviceDetectorService, private dialogService: DialogService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
        console.log(localStorage.getItem('allowMobile'))
        if(localStorage.getItem('allowMobile') === null) {
          this.dialogService.open(MobileAlertComponent).pipe(take(1)).subscribe(data => {
            localStorage.setItem('allowMobile', JSON.stringify(true));
          });
        }
      }
      resolve(true);
    });

  }
}
