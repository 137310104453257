import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { Auth } from "@aws-amplify/auth";
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import { EntityDefinitionService, EntityDataService } from "@ngrx/data";
import { AstrakodeBcLibraryModule } from "astrakode-bc-library";
import { LoginComponent } from "./login/login.component";
import { LoginTermsComponent } from "./login-terms/login-terms.component";
import { LoginPrivacyComponent } from "./login-privacy/login-privacy.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { RecoverComponent } from "./recover/recover.component";
import { AuthComponent } from "./auth.component";
import { entityMetadata, AUTH } from "./_model/auth.model";
import { AuthEntityService, AuthDataService, LoaderService } from "./_services";
import { HttpInterceptorProviders } from "./_interceptors/auth-interceptor-provider";
import { AuthGuard } from "./_guards/auth.guard";
import { routes } from "./_routes";

// Amplify
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import AWSConfig from "./aws-exports";
import Amplify from "aws-amplify";
import { TranslateModule } from "@ngx-translate/core";
import {MobileGuard} from "./_guards/mobile.guard";
import {MobileAlertComponent} from "./mobile/mobile-alert.component";

Amplify.configure(AWSConfig);
Auth.configure(AWSConfig);

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AmplifyUIAngularModule,
    AmplifyAngularModule,
    AstrakodeBcLibraryModule,

    TranslateModule.forChild(),
  ],
  exports: [AuthComponent, LoginComponent, RouterModule, LoginTermsComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    SignUpComponent,
    RecoverComponent,
    LoginTermsComponent,
    LoginPrivacyComponent,
    MobileAlertComponent
  ],
  providers: [
    AmplifyService,
    AuthEntityService,
    AuthDataService,
    LoaderService,
    AuthGuard,
    MobileGuard,
    HttpInterceptorProviders,
  ],
})
export class AuthModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private authDataService: AuthDataService
  ) {
    eds.registerMetadataMap(entityMetadata);

    entityDataService.registerService(AUTH, authDataService);
  }
}
