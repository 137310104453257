import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Auth } from "aws-amplify";

import { EMPTY, from, Observable, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
// import { ToasterService } from "astrakode-bc-library";
import { environment } from "projects/AstrakodeBCPortal/src/environments/environment";
import { en, es, zh, it } from "../../../assets/i18n";
import { ToasterService } from "astrakode-bc-library";
import { AuthStateService } from "../../shared/_service/auth-state.service";
// This will append jwt token for the http requests.
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  errors = {
    en,
    es,
    zh,
    it,
  };
  constructor(
    private injector: Injector    
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        let jwt = auth.idToken.jwtToken;
        let user = JSON.parse(window.localStorage.getItem("Auth"));
        const contextName = request.body?.contextName;
        const pruneBody = request.body;
        if (contextName) delete pruneBody.contextName;
        let with_auth_request = request.clone({
          setHeaders: {
            Authorization: `${jwt}`,
            lang: localStorage.getItem("lang") || "enUS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          body: {
            ...pruneBody,
            context: {
              language: localStorage.getItem("lang") || "enUS",
              userName: user && user.email ? user.email : "",
              ...(contextName && {
                contextName,
              }),
            },
          },
        });
        return next.handle(with_auth_request);
      }),
      catchError((err) => {
        if (err === "No current user") {         
          return next.handle(request);
        }
        if (err.type === environment.NO_PAIR_TYPE) {
          //this.toasterService.error(err.messageCustom);
          console.log("Este error" + err.messageCustom);
          return throwError(new Error(err.messageCustom));
        } else {
          let lang = localStorage.getItem("lang") || "enUS";
          if (typeof err == "string") {
            const toasterService= this.injector.get(ToasterService);
            if (err.includes("token has expired")) {
              toasterService.error(this.errors[lang].Login.Expired);
            } else if (err.includes("Unauthorized")) {
              toasterService.error(this.errors[lang].Login.Expired);
            } 
          }          
        }
        // return from(Auth.currentSession()).pipe(
        //   switchMap((auth: any) => {
        //     let jwt = auth.idToken.jwtToken;
        //     let with_auth_request = request.clone({
        //       setHeaders: {
        //         Authorization: `${jwt}`,
        //         lang: localStorage.getItem("lang") || "enUS",
        //         "Access-Control-Allow-Headers": "Content-Type, Authorization",
        //       },
        //     });
        //     return next.handle(with_auth_request);
        //   })
        // );
        // return EMPTY;
        return throwError(err);
      })
    );
  }
}
