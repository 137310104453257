import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { EMPTY, of, from } from "rxjs";
import { map, mergeMap, catchError, switchMap, pluck, tap} from "rxjs/operators";
import { ProjectsService } from "../../../projects/_services/projects.service";
import { CrmService } from "../../../shared/_service/crm.service";
import { ToasterService } from "astrakode-bc-library";
import { TranslateService } from "@ngx-translate/core";
import {sharedStore, sharedModels} from "astrakode-bc-library";

@Injectable()
export class ProjectDraftEffects {
  constructor(
    private actions$: Actions,
    private projectsService: ProjectsService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private crmService: CrmService
  ) { }

  /**
   * Creates a new project and adds it to the storage
   *
   * @memberof ProjectDraftEffects
   */
  createProjectDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedStore.CREATE_PROJECT_DRAFT),
      mergeMap((action: any) => {
        let project = {};
        let branches;
        return this.projectsService.createProject(action.payload).pipe(
          tap((projectResp: any) => project = projectResp),
          switchMap(projectResp => this.projectsService.getProjectData(projectResp.project.id)),
          switchMap((branchesResp: any) => {
            branches = branchesResp;
            const updateCRM = {
              caller: "CREATE_PROJECT_DRAFT Effect",
              customerEmail: action.payload.creationUser,
              action: action.payload.type == 1 ? "createNetwork" : "createLogic"
            };
            return from(this.crmService.updateCRM(updateCRM));
          }),
          switchMap((rcmUpdateResp) => [
            this.formatProjectCreated(project, branches),
            new sharedStore.GetPairsCallSuccess(),
            new sharedStore.UpdateProductsCounterAfterAddProject(project["project"])
          ]),
          catchError((e) => {
            return of(new sharedStore.CreateProjectDraftFail({ message: "Problem creating the project!" }));
          })
        );
      })
    )
  );
  /**
   * Formats the new project created to correspond to the model
   *
   * @param {*} project
   * @return {*}
   * @memberof ProjectDraftEffects
   */
  formatProjectCreated(project, branches) {
    let model: sharedModels.ProjectDraft = {
      id: project.project?.id,
      branchId: branches.listBranch[0].id,
      accessible: project.project?.accessible,
      name: project.project?.name,
      author: project?.name,
      lastSaved: project.context.timestamp
        ? parseInt(project.context.timestamp)
        : 0,
      configuration: {
        version: project.project.version,
        type: project.project.type,
        collabs: project.project.collabs,
      },
      group: {
        id: parseInt(project.group.id),
        name: project.group.name,
        description: project.group.description,
      },
    };
    return new sharedStore.CreateProjectDraftSucess(model);
  }
}
