import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuardService } from "./auth/_auth-guard/auth-guard.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { MobileGuard } from "./auth/_guards/mobile.guard";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: "login/:email", pathMatch: "full", redirectTo: "login" },
  { path: "sign-up", pathMatch: "full", redirectTo: "sign-up" },
  { path: "recover", pathMatch: "full", redirectTo: "recover" },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "services",
    loadChildren: () => import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "projects",
    loadChildren: () =>
      import("./projects/projects.module").then((m) => m.ProjectsModule),
    canActivate: [AuthGuardService, MobileGuard],
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuardService, MobileGuard],
  },
  {
    path: "plans",
    loadChildren: () =>
      import("./plans/plans.module").then((m) => m.PlansModule),
    canActivate: [AuthGuardService, MobileGuard],
  },
  { path: "**", redirectTo: "login" },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
