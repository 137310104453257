import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-terms',
  templateUrl: './login-terms.component.html',
  styleUrls: ['./login-terms.component.scss']
})
export class LoginTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
