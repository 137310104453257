<div class="auth-wrapper">
  <section class="auth-main-wrapper">
    <router-outlet></router-outlet>
  </section>
  <footer
    *ngIf="!((isPrivacy$ | async) || (isTerms$ | async))"
    class="footer-wrapper"
  >
  </footer>
</div>
