import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { Auth, AUTH } from "../_model/auth.model";
import { Auth as AuthAWS } from "aws-amplify";
import { AuthModalComponent } from "astrakode-bc-library";
import { environment } from "../../../environments/environment";
import "rxjs/add/observable/fromPromise";

@Injectable()
export class AuthDataService extends DefaultDataService<Auth> {
  private currentUserSubject: BehaviorSubject<Auth>;
  private currentTokenSubject = new BehaviorSubject<any>(null);
  private currentWidgetState = new BehaviorSubject<any>(false);
  private interval;
  public currentUser: Observable<Auth>;
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(AUTH, http, httpUrlGenerator);
    this.setCurrentUser();
  }

  // isSignedIn(): Observable<boolean> {
  //   // AMPLIFY CALL HERE
  //   return false;
  // }

  /**
   * It calls an external geolocation api service.
   * @returns the following json:
   * {
        "ip" : "8.8.8.8"
        "city" : "Mountain View"
        "region" : "California"
        "region_code" : "CA"
        "country_code" : "US"
        "country_code_iso3" : "USA"
        "country_name" : "United States"
        "country_capital" : "Washington"
        "country_tld" : ".us"
        "continent_code" : "NA"
        "in_eu" : false
        "postal" : "94035"
        "latitude" : 37.386
        "longitude" : -122.0838
        "timezone" : "America/Los_Angeles"
        "utc_offset" : "-0700"
        "country_calling_code" : "+1"
        "currency" : "USD"
        "currency_name" : "Dollar"
        "languages" : "en-US,es-US,haw"
        "asn" : "AS15169"
        "org" : "Google LLC"
      }
   */

  getUserGeolocation(caller) {    
    return this.http.post(`${environment.API_URL}AK_CallExternalAPI`, {caller: caller, action: "geolocation"});
    // return this.http.get(`${environment.GEOLOCATION_API}`);
  }

  /**
   * Signups the user using Cognito services
   *
   * @param {*} userData
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoSignUp(userData: any) {
    return Observable.fromPromise(AuthAWS.signUp(userData));
  }

  /**
   * Signins the user using Cognito services
   *
   * @param {*} userData
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoSignIn(userData: any) {
    return Observable.fromPromise(AuthAWS.signIn(userData));
  }

  /**
   * sends the verification signup code using cognito services
   *
   * @param {string} userName
   * @param {string} verifycode
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoConfirmSignup(userName: string, verifycode: string) {
    return Observable.fromPromise(
      AuthAWS.confirmSignUp(userName, verifycode, { forceAliasCreation: true })
    );
  }

  /**
   * Confirms the verification code sended to the user
   *
   * @param {*} code
   * @param {*} username
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoConfirmAcount(code: any, username: any) {
    return Observable.fromPromise(AuthAWS.confirmSignUp(username, code));
  }

  /**
   * Initiates the recover password function of cognito
   *
   * @param {*} username
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoInitChangePassword(username: any) {
    return Observable.fromPromise(AuthAWS.forgotPassword(username));
  }

  /**
   * Change the user password using the cognito servicees
   *
   * @param {string} username
   * @param {string} code
   * @param {string} password
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoConfirmChangePassword(
    username: string,
    code: string,
    password: string
  ) {
    return Observable.fromPromise(
      AuthAWS.forgotPasswordSubmit(username, code, password)
    );
  }

  /**
   * Resends the singup code using cognito services
   *
   * @param {*} username
   * @return {*}
   * @memberof AuthDataService
   */
  cognitoResendCode(username) {
    return Observable.fromPromise(AuthAWS.resendSignUp(username));
  }

  /**
   * Return the current user value
   *
   * @readonly
   * @type {Auth}
   * @memberof AuthDataService
   */
  public get currentUserValue(): Auth {
    return this.currentUserSubject.value;
  }

  /**
   * Sign in the user using congito services
   *
   * @param {*} userData
   * @return {*}
   * @memberof AuthDataService
   */
  signin(userData: any) {
    return Observable.fromPromise(AuthAWS.signIn(userData)).pipe(
      map((data: any) => {
        let user = {
          userId: data.attributes.sub,
          username: data.attributes.name,
          email: data.attributes.email,
          org: data.attributes["custom:company"],
        };
        localStorage.setItem("Auth", JSON.stringify(user));
        this.currentUserSubject.next(user);
        user = {
          ...user,
          ...data.attributes
        };
        return user;
      })
    );
  }

    /**
   * Sign in the user using
   *
   */
    signinFromIDP(userAttributes: any) {
      const user = {
        userId: userAttributes.sub,
        username: userAttributes.name,
        email: userAttributes.email,
        org: "",
      };
      localStorage.setItem("Auth", JSON.stringify(user));
      this.currentUserSubject.next(user);
      return this.currentUser;
    }

  /**
   * Get the current user from the local storage and creates a subject with it
   *
   * @memberof AuthDataService
   */
  setCurrentUser() {
    this.currentUserSubject = new BehaviorSubject<Auth>(
      JSON.parse(localStorage.getItem("Auth"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Updates the user subjec with the logged in user info
   *
   * @param {*} user
   * @return {*}
   * @memberof AuthDataService
   */
  loginSubjectEmiter(user: any) {
    localStorage.setItem("Auth", JSON.stringify(user));
    this.currentUserSubject.next(user);
    return this.currentUser;
  }

  /**
   * Updates the user subjec with null and delete Auth from the local storage
   *
   * @return {*}
   * @memberof AuthDataService
   */
  logoutSubjectEmiter() {
    localStorage.removeItem("Auth");
    this.currentUserSubject.next(null);
    return this.currentUser;
  }

  /**
   * Return an observable base on the current user Subject
   *
   * @return {*}  {Observable<Auth>}
   * @memberof AuthDataService
   */
  isLoggedIn(): Observable<Auth> {
    return this.currentUserSubject.asObservable();
  }

  /**
   * Check if there exists Auth on the local storage
   *
   * @private
   * @return {*}  {boolean}
   * @memberof AuthDataService
   */
  private hasToken(): boolean {
    return !!localStorage.getItem("Auth");
  }

  /**
   * Return the collaborator of the user
   *
   * @return {*}
   * @memberof AuthDataService
   */
  getUserCollaborators() {
    return this.http.get(`${environment.API_URL}collaborators`);
  }

  // to uncomment when a Tribe will be replaced 
  // /**
  //  * Asks the API for a new tribe token
  //  *
  //  * @memberof AuthDataService
  //  */
  // getNewTribeToken() {
  //   AuthAWS.currentUserInfo().then((data) => {
  //     let newInfo = {
  //       email: data.attributes.email,
  //       id: data.attributes.sub,
  //       name: data.attributes.name,
  //     };
  //     this.http
  //       .post(`${environment.API_URL}AK_TribeRefresh`, newInfo)
  //       .toPromise()
  //       .then((data) => {
  //         this.currentTokenSubject.next(data);
  //       });
  //   });
  // }

  /**
   * Returns the current token subject
   *
   * @return {*}
   * @memberof AuthDataService
   */
  getCurrentTokenSubject() {
    return this.currentTokenSubject;
  }
  getCurrentWidgetState() {
    return this.currentWidgetState;
  }

  /**
   * Asks the API to check the captcha
   *
   * @param {*} token
   * @return {*}
   * @memberof AuthDataService
   */
  validateCaptchaToken(token) {
    return this.http.post(`${environment.API_URL}AK_captcha`, { token });
  }
}
