import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { GuideTourType } from "../_enums";

export const GUIDED_TOUR_KEY = "guidetour.projects.home";

@Injectable({
  providedIn: "root",
})
export class GuideToursService {
  body: HTMLBodyElement;

  constructor(
    private deviceService: DeviceDetectorService
  ) {
    this.body = document.querySelector("body");
  }

  /**
   * Checks if the user has already complete the tour
   *
   * @param {GuideTourType} type
   * @return {*}
   * @memberof GuideToursService
   */
  canStart(type: GuideTourType) {
    let result: boolean = true;

    if (this.deviceService.isMobile()) {
      return false;
    }

    switch (type) {
      case GuideTourType.Projects_Home:
        result = !localStorage.getItem(GUIDED_TOUR_KEY);

        localStorage.setItem(GUIDED_TOUR_KEY, "true");
        break;
      default:
        break;
    }

    return result;
  }

  /**
   * Checks if the user has already complete the tour
   *
   * @param {GuideTourType} type
   * @return {*}
   * @memberof GuideToursService
   */
   canStartComposerTour(type: GuideTourType) {
    let result: boolean = true;

    if (this.deviceService.isMobile()) {
      return false;
    }

    switch (type) {
      case GuideTourType.Projects_Home:
        result = !localStorage.getItem(GUIDED_TOUR_KEY);

        localStorage.setItem(GUIDED_TOUR_KEY, "true");
        break;
      default:
        break;
    }

    return true;
  }

  /**
   * Starts the guide tour
   *
   * @param {GuideTourType} type
   * @param {boolean} [skipCanStart]
   * @memberof GuideToursService
   */
  start(type: GuideTourType, skipCanStart?: boolean) {    
    let onboardingTourDisabled: boolean = localStorage.getItem("onboardingTourDisabled") === "true";
    
    if (!onboardingTourDisabled && (skipCanStart || this.canStart(type))) {
      if (type == GuideTourType.Projects_Home) {
        
        const steps = [
          { step: 1, name: "portal.welcome", scrollTo: 0 },
          { step: 2, name: "portal.left-column", scrollTo: 0 },
          { step: 3, name: "portal.left-column.nav" },
          { step: 4, name: "portal.left-column.footer" },
          //{ step: 5, name: "portal.right-column", scrollTo: 0 },
          //{ step: 5, name: "portal.header", scrollTo: 0 },
          //{ step: 6, name: "portal.header.user", scrollTo: 0 },
          // { step: 8, name: "projects.home", scrollTo: 0 },
          { step: 5, name: "projects.home.add", scrollTo: 0 },
          //{ step: , name: "portal.header.alerts", scrollTo: 0 },
        ];

        const options = {
          stepDefaultPosition: "top",
          steps: steps.map((item) => item.name),
          themeColor: "#95A6B5",
        };

        // remove scroll
        document.querySelector("body").style.overflow = "hidden";
        window.scroll(0, 0);
      }
    }
  }

    /**
   * Starts the guide tour
   *
   * @param {GuideTourType} type
   * @param {boolean} [skipCanStart]
   * @memberof GuideToursService
   */
     startComposerTour(type: GuideTourType, skipCanStart?: boolean) {
      let netCompOnBoardingDisabled: boolean = localStorage.getItem("netCompOnBoardingDisabled") === "true";

      if (!netCompOnBoardingDisabled && (skipCanStart || this.canStartComposerTour(type))) {
        
          const steps = [
            { step: 1, name: "composer.welcome", scrollTo: 0 },
            { step: 2, name: "composer.header", scrollTo: 0 },
            { step: 3, name: "composer.header.save" },
            { step: 4, name: "composer.header.settings" },
            { step: 5, name: "composer.header.changes", scrollTo: 0 },
            { step: 6, name: "composer.header.show", scrollTo: 0 },
            { step: 7, name: "composer.header.share", scrollTo: 0 },
            { step: 8, name: "composer.header.generate", scrollTo: 0 },
            { step: 9, name: "composer.main.workingarea", scrollTo: 0 },
            { step: 10, name: "composer.left-column.organizations", scrollTo: 0 },
            { step: 11, name: "composer.left-column.consortia", scrollTo: 0 },
            { step: 12, name: "composer.left-column.channel", scrollTo: 0 },
            { step: 13, name: "composer.main.workingarea.add", scrollTo: 0 },
            { step: 14, name: "composer.right-column.issues", scrollTo: 0 },
          ];
  
          const options = {
            stepDefaultPosition: "top",
            steps: steps.map((item) => item.name),
            themeColor: "#95A6B5",
          };
    
          // remove scroll
          document.querySelector("body").style.overflow = "hidden";
          window.scroll(0, 0);
        }
      }

    onDone() {  
          
      localStorage.setItem("onboardingTourDisabled", "true");
    }

    onDoneNetCompOnBoarding() {      
      
      localStorage.setItem("netCompOnBoardingDisabled", "true");
    }
}
